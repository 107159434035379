.myProfile{
    .flexWhiteBox{
        flex-flow: row;
        .bodyRow{
            .flexBox{
            ul li span.value{
                    max-width:295px;
                    width:100%;    
                    display: inline-grid;            
                }
                ul li span.label{
                    width: 40%;           
                }

            }
        }
    }
}


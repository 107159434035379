span.material-icons.blankCheckbox {
    color: #D6DFE4;
    border-radius: 3px;
    font-size: 32px;
}
span.material-icons.selectedCheckbox {
    color: #39c;
    border-radius: 3px;
    font-size: 32px;
        float: left;
    margin-right: 10px;
}

input[type='checkbox'] {
    opacity: 0;
    float: left;
    width: 22px;
    position: absolute;
    left:34px;
}	
input[type='checkbox'] ~ span.material-icons.blankCheckbox {margin: 0;clear: none;float: left;
    margin-right: 10px;}
input[type='checkbox'] ~ span.material-icons.selectedCheckbox{display: none;}
input[type='checkbox']:checked ~ span.material-icons.selectedCheckbox {display:block;}
input[type='checkbox']:checked ~ span.material-icons.blankCheckbox{display:none;}
label.rememberCheck{line-height: 32px;margin-bottom: 0px;}

@media screen and (max-width: 767px){
    .loginWrapper input[type='checkbox'] {
        left:0px;
    }	

    .loginWrapper { background: #F9FAFB !important;}
    .mobileView.loginNotification{margin: 0 10px;}
}
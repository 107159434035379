$bodyDefaultColor:#1A3547;

.productsDesc{
    .productsDescTemplate{margin-bottom: 20px;}
    .mainHeadingT{
        float: left;
        a,a:hover{color:$bodyDefaultColor !important;font-size: 24px;position: relative; top: -2px;}
        margin-bottom:20px;
    }
    .homeHederText{margin-bottom:15px;}
    .twoColLayout{
        width: 100%;
            display: flex;
            margin-top:20px;
        .leftCol{width: 480px;float:left;margin-right:15px;
            .flexWhiteBox{
                min-height:auto;
                margin-top:0px;
                
            }
        }
        
        .leftCol:nth-child(even) {margin-right: 0px;}
        .leftCol .leftColumn{margin-right: 0px !important;}
         .leftColumn .bodyRow .flexBox ul li span.label{width:205px;}
         
    }
    .optionalRider {
            margin-top: 20px;
             span.nonAnchorText {display: block;max-width: 232px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;color:$bodyDefaultColor;}
        }
                .tableForm{
                    .productTable{
                        margin-bottom:0px;
                         
                    }
                }
            .productDocs ul li{ border-bottom:1px solid #ECF0F3; 
                .smallTableText{display:block}
                }
         .productDocs ul li:last-child{border:none;}    
}

@media screen and ( width:768px ){
    .productsDesc .twoColLayout{
        display:inline-block;
    }
    .productsDesc .twoColLayout .leftCol{
        margin-bottom:15px;
    }
}
@media screen and (max-width: 767px){
.productsDesc{
.mainHeadingT a,
.mainHeadingT a:hover {
    //color: #1A3547 !important;
    font-size: 36px;
    float: left;
    position: relative;
    top: -3px;
}
.productDocs ul li.collapse-text .priceValue{visibility: hidden;}
.flexWhiteBox{
    min-height:auto;
    margin-top:0px;
    .leftColumn .bodyRow .flexBox ul li span.label, 
    .rightColumn .bodyRow .flexBox ul li span.label {
    width: 205px;
            }
        }
     li.collapse-text{cursor: pointer;}   
     .collapse-text[aria-expanded="false"] .value .materialicons.downArrow{display:none;}
     .collapse-text[aria-expanded="true"] .value .materialicons.downArrow{display:block;text-align: right;}
     .collapse-text[aria-expanded="true"] .value .materialicons.leftArrow{display:none;}
     .collapse-text[aria-expanded="false"] .value .priceValue{visibility: hidden;}
    .collapse-text[aria-expanded="true"] .value .priceValue{visibility: visible;}
    .collapse-text[aria-expanded="true"] .label{font-weight: 500 !important;}
    }
    .productsDesc .flexWhiteBox .leftColumn .bodyRow .flexBox ul li.collapse-text span.label, 
    .productsDesc .flexWhiteBox .rightColumn .bodyRow .flexBox ul li.collapse-text span.label{width:74%;}
     .productsDesc .flexWhiteBox .leftColumn .bodyRow .flexBox ul li.collapse-text span.value, 
    .productsDesc .flexWhiteBox .rightColumn .bodyRow .flexBox ul li.collapse-text span.value{width: 26%;display: inline-block;}
    .productDocs .collapseText .flexBox ul{
        border-top:none;
        li{border:none;}
    }
    .productsDesc .productDocs ul li.collapse-text[aria-expanded="true"]{border:none;}
    .productsDesc .productDocs ul li.collapse-text .priceValue {margin-right: 10px;}
     .footPagination{
    text-align: center;
    width: 100%;
    display: inline-block !important;}
    .productsDesc .homeHederText {
           margin-bottom: 10px;
      }
}
span.accountInfoTab.false {
  cursor: pointer;
}

.rememberMethod span:last-child {
  position: relative;
  top: 3px;
}
.rememberMethod input[type="checkbox"] {
  left: 21px;
}

.registerWrapper {
    margin-bottom:50px;
    .noteText{margin-top:15px;}
    .innerBorder{ 
        width: 430px;
        position: absolute;
        left: 142px;
        top: 21px;
img{
    width: 430px;
    height: 5px;
    display: flex;margin:0 auto;
    }
}
    .nav-pills {width: 646px;height: 72px; flex-direction: column;justify-content: center;display: flex;position: relative;margin: 20px auto 70px auto;
        .nav-item{text-align: center;width: 235px; position: relative;z-index: 1;}
    }
    span.numberText {
            position: relative;
            left: 27px;
            top: 3px;
            font-weight: 700;
            font-size: 18px;
            color:#A5B9C5;
     }
     .menuText{margin-top: 4px;display: inline-block;}
    .active  span.numberText {color:#fff;}
    .visited span.numberText {color:#fff;}
    .active  span.menuText{font-weight: 700;}
        .securityQuestion {
    margin-bottom: 20px;
    margin-top: 20px;
}

.headingText{font-size: 18px;}
.loginCred .password-validation-ctnr {
    margin-top: 100px;
}
.loginCred .roundBottomButton{
    width:100%;
    float: left;
}
.privacyPolicy span:last-child {
    position: relative;
    top: 5px;
}
.privacyPolicy{margin-left:-30px;}
.leftCol.widthCol.desktopView{    max-width: 45%;}

    }
.registarPop .modal-body img {
    max-width:470px;
    width:100%;
    margin-bottom: 15px;
    padding: 0px;
    height: auto;
    border:1px solid #D6DFE4;
    padding:5px;
}
.registarPop .modal-body img:first-child{
    margin-top:12px;
}
    @media screen and (width:768px){
        .registerWrapper .nav-pills{
            width:706px;
        }
        .backgroundSetColor .captcha{
            padding:10px;
        }
    }

      @media screen and (max-width:767px){
          .registerWrapper{
                margin-bottom: 10px;
          }
          .backgroundSetColor{
                  float: none;
          }
          .registerWrapper .nav-pills{
            width: 100%;
            height: 40px;
            margin: 20px auto 10px;
            
          }
          .registerWrapper .innerBorder {
                width: 100%;
                position: absolute;
                left: 0px;
                top: 19px;
            }
            .registerWrapper .innerBorder img{
                width: 85%;
            }
            .registerWrapper .nav-pills .nav-item{
                width:40%;
                text-align: left;
            }
            .registerWrapper .menuText{
                display: none;
            }
            .noteMobileText{
                border-width: 1px;
                border-style: solid;
                border-color: #ffed8d;
                border-radius: 4px;
                background-color: #fffada;
                padding: 0px 10px;
                margin: 20px 0px 4px 0px;
            }
            .registerWrapper .loginCred .password-validation-ctnr{margin-top:20px !important;}
            .registerWrapper .nav-pills .nav-item:last-child {
                       width: auto;
                    }
      }

.custom-fluid .agiaBlueButton-outline.grayButton, .modal .agiaBlueButton-outline.grayButton:hover,
.custom-fluid .agiaBlueButton-outline.grayButton, .modal .agiaBlueButton-outline.grayButton:focus {
    background: transparent;
    color: #1A3547;
    border: 1px solid #d6dfe4;
    font-weight: 400;
   box-shadow: none;
}
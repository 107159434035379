.noDuesContent .bodyRow .noDues{
    // padding: 20%;
     display: flex;
    flex-direction: column;
    justify-content: center;
    height: 271px;
}
.noDuesContent .headerColumn{border:none;}
.noDuesContent .materialicons,.noDuesContent .dueText {
    width: 100%;
    display: block;
    text-align: center;
    float:left;
}
.noDuesContent label.materialicons{
	font-size:40px;
	color:rgb(165, 185, 197);
	float: left;
	width: 100%;
    line-height: 40px;
    text-align: center;
}
.noDuesContent .dueText {font-size:20px;color:rgb(125, 142, 155);}
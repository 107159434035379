table {
  tr {
    outline: none;
  }

  td:last-child {
    //width: 190px;
    button {
      margin-bottom: 10px;
    }
  }
}

.cunsumerHome {
  .cunsumerSearchBody {
    background-color: #fff;
    box-sizing: content-box;
    border: 1px solid #ecf0f3;
    border-radius: 3px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.08);
    padding: 15px;
  }
  table.searchCustomer {
    margin-top: 15px;
  }
  .searchCustomer td button {
    // width: 47%;
    text-align: center;
    margin-right: 10px;
    margin-bottom: 10px !important;
    padding: 0 !important;
  }
  .custom-fluid table.searchCustomer td {
    // vertical-align: text-top;
  }
  .custom-fluid table.searchCustomer td:nth-child(5) {
    width: 20%;
  }

  .notification-container {
    display: grid;
  }
}

.table-wrapper {
  max-height: 500px;
  position: relative;
  transition: all 0.2s ease-out;
  table{
    display: table;
  }
  // @media(min-width: $screen-sm-min) {
  //   background: white;
  //   box-shadow: $shadow;
  // }
}

.card-list-table {
  table-layout: fixed;
  background: transparent;
  margin-bottom: 0;
  width: 100%;
  margin-top: 10px;
  thead {
    display: none;
  }
  tbody tr {
    // box-shadow: $shadow;
    // background: #fff;
    // border-bottom: 1px solid $gray-border;
    cursor: pointer;
    display: block;
    padding: 15px 10px;
    margin: 0 0 10px 0;
    border-radius: 3px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.08);
  }
  tbody td {
    border: 0;
    display: block;
    padding: 10px 10px 20px 50%;
    position: relative;
    &:first-of-type::after {
      visibility: hidden;
    }
    &:after {
      content: "";
      width: calc(100% - 30px);
      display: block;
      margin: 0 auto;
      height: 1px;
      // background: $gray-border;
      position: absolute;
      left: 0;
      right: 0;
      top: -6px;
    }
    &:before {
      // color: $gray-light;
      text-transform: uppercase;
      font-size: 0.85em;
      content: attr(data-title);
      display: table-cell;
      font-weight: 500;
      height: 100%;
      left: 15px;
      margin: auto;
      position: absolute;
      vertical-align: middle;
      white-space: nowrap;
      width: 40%;
    }
  }
  thead th {
    text-transform: uppercase;
    font-size: 0.85em;
    // color: $gray-light;
    letter-spacing: 0.5pt;
  }

  .row-btns {
    padding: 0 !important;
    margin-top: 20px;
  }
}

.productsTemplate{margin-top: 30px;width: 100%;float: left;
    .homeHederText {color: #1A3547; font-size: 20px;font-weight: 500;font-style: normal; padding-bottom: 15px;margin-bottom:0px;}
    .productTable {margin-bottom: 30px;}
    .footerTextPara{padding:30px 0px;float:left;width:100%;font-size:14px;}
}
.roundBottomButton button { margin-right: 10px;border-radius: 40px;padding: 0px 25px;}
.modal{
    .subHeading{ font-size: 1rem;font-weight: 400;margin-bottom: 15px;margin-top: 15px;}
    .bodyRow{
        .flexBox{
            ol{    
                float: left;
                width: 100%;
                padding:0px;
                li{
                    text-align: left;
                    padding:10px 0px;
                    select{
                        width:100%;
                        padding: 0px 10px;
                    }
                }
                li:nth-child(n){
                    width:40%;float: left;
                    font-size: 14px;
                }
                li:nth-child(2n){
                    width:60%;float: left;
                    font-size: 16px;
                    font-weight: 400;
                }
            }
        }
    }
    .squareButton button{border-radius: 0px;margin-right:5px;}
}
@media screen and (max-width: 767px){
    // .mobileView.homePayDues .singleCol .homeHederText{display: none;}
    // .mobileView.homePayDues .singleCol .flexWhiteBox {margin-bottom: 15px;}
    .mobileView.homePayDues .flexWhiteBox .leftColumn{margin-bottom:0px;}
    .footerCol .bottomButton .payNow {max-width: 95%;}
    .paymentCenterContent .footerCol{
        border-top:1px solid  #d6dfe4;
        // padding:10px 0px;
        width:100%;
         float:left;
        .leftText{
            width:50%;
            float:left;
                text-align: center;
                    line-height: 40px;
                    font-size: 16px;
                    font-weight: 500;
        }
        .rightBtn{
            width:50%;
            float:left;
           
            button{width:100%;border-radius: 0px;}
        }
        .singleCol.leftText{width:100%;}
    }
    .footerTextPara{width:100%;float: left;font-size: 14px;}
}
@media screen and (max-width: 480px) {
  .paymentCenterContent .footerCol .leftText{font-size: 14px;}
  .modal .bodyRow .flexBox ol li:nth-child(n){width: 50%;font-size: 13px;}
  .modal .bodyRow .flexBox ol li:nth-child(2n){width: 50%;font-size: 14px;}
  body.modal-open .modal .modal-header h4{ font-size: 15px;}
//   .modal .subHeading{font-size: 15px;}
}

@media screen and (max-width: 768px){
    .tooltipHover.buttonTooltip {
        left: -10px;
    }
}
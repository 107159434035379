.password-validation-ctnr{
 margin-top: 15px;
 margin-bottom: 20px;
 span{padding-left:15px;display: inline-block;}
 span.passwordHeadingText{
      margin-bottom: 5px;
    display: inline-block;
        padding-left: 0px;
 }
 span.invalid,span.valid {
    padding-left: 25px;
  }
}

.valid{
  color: green;
}

.invalid{
  color: #e25c83;
}

.backgroundSetColor{
      // display: flex;
  .columnLayout{width:100%;float:left;
    .leftCol { max-width: 480px;float: left; width: 100%; margin-right: 15px; padding: 15px;}
  }
}

@media screen and (max-width:992px){
  .backgroundSetColor .columnLayout .leftCol{
    max-width: 368px;
    margin-right: 0px;
    padding: 10px;
  }
}

@media screen and (width:768px){
.backgroundSetColor .columnLayout .leftCol{
    max-width: 368px;
    margin-right: 0px;
    padding: 10px;
  }
}

@media screen and (max-width:767px){
.backgroundSetColor .columnLayout .leftCol{
      padding: 10px;
      margin-right:0px;
}
.custom-fluid.container-fluid .mainWrapper .bodyWrapper .usernameWrapper.resetPwd{
  margin-top:0px;
}
.password-validation-ctnr{
  margin-top:-20px;
}

}
@media screen and (max-width:360px){
  .password-validation-ctnr span{
        font-size: 13px;
  }
}
.backgroundSetColor{ 
    width:100%;
    float:left;
    box-shadow: rgba(0, 0, 0, 0.08) 2px 2px 10px;
    border-color: #ecf0f3;
    background-color: #fff;
    border-width: 1px;
    border-style: solid;
    border-radius: 3px;
    margin-bottom:20px;
    .formLayout{
        max-width:480px;
        margin: 15px;
    }
    .captcha {
        padding: 0px 15px 15px;
        margin-bottom: 15px;
    }
    a.findPlanText {
    padding: 15px 0px;
    display: inline-block;
    }
}
.roundBottomButton a {
    display: inline-flex;
    justify-content: center;
    flex-direction: column;
    position: relative;
    top: 10px;
    left: 15px;
}
.roundBottomButton a:hover{text-decoration: underline !important;}

@media screen and (max-width:767px){
    
    .usernameWrapper{
        padding:10px !important;
    }
    .backgroundSetColor{
    .formLayout{
        margin: 10px;
        }
    }
    .backgroundSetColor .captcha{    padding: 0px 10px 10px;margin-bottom: 10px;}
}

@media screen and (max-width:380px){
    .custom-fluid .agiaBlueButton,
    .custom-fluid .agiaBlueButton-outline{
            font-size: 14px;
    }
    .usernameWrapper .mobileButton .roundBottomButton button{
            margin-right:0px;
    }
      .usernameWrapper .mobileButton .roundBottomButton a{
              font-size: 14px;
      }
     body.modal-open .modal .modal-header .modal-title{
         font-size: 16px !important;
     } 
     body.modal-open li span.label{
             width: 170px !important;
                 font-size: 12px;
     }
    body.modal-open li span.value {
            font-size: 14px;
        }
        .mobileFooterNav .innerfootWapper ul li label.navText{
                font-size: 13px;
        }
        .bodyPanelMore .navbarText ul li label.navText{
                font-size: 13px;
        }
}
@media screen and (max-width:320px){
    .roundBottomButton button{
        font-size: 14px;
        padding: 0px 12px !important;
    }   
.custom-fluid .agiaBlueButton, 
.modal .agiaBlueButton,
.custom-fluid .agiaBlueButton-outline{
            font-size: 13px;
    }
    .usernameWrapper .mobileButton .roundBottomButton button{
            padding: 0px 12px;
    }
      .usernameWrapper .mobileButton .roundBottomButton a{
              font-size: 13px;
                  left: 6px;
      }
}
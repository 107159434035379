$bodyDefaultColor: #1a3547;
$base-color: #f9fafb;
$anchor-color: #3399cc;
$redColorText: #fc1111;
$active-button-color: #3399cc;
$active-button-color-hover: #2b7fae;
// $non-active-button:#ddd;
// $common-grayColor:#aaa;
$white-color: #fff;
$label-color: #62717f;
$label-color-1: #7d8e9b;
$footerColor: #ecf0f3;
$warningYellow: #ffc107;
$blackColor: #212121;
$inputFocusColor: #bff3ff;
$inputBorder: #ced4da;
$mandatoryField: #a30014;
$tableFontColor: #62717f;
$duePayment: #e25c83;
$autoPayButton: #65cf87;
$default: #555;
$testing-font: #555;
$borderDark: #d6dfe4;
$lightBorder: #ecf0f3;
$warningAlertColor: #fceff3;
$successAlert: #e8f8ed;
$closeIconColor: #a5b9c5;
/*Standard Font-size*/
$font-size-11: 0.688rem;
$font-size-12: 0.75rem;
$font-size-13: 0.813rem;
$font-size-14: 0.875rem;
$font-size-15: 0.938rem;
$font-size-16: 1rem;
$font-size-18: 1.125rem;
$font-size-20: 1.25rem;
$font-size-21: 1.3125rem;
$font-size-22: 1.375rem;
$font-size-23: 1.4375rem;
$font-size-24: 1.5rem;
$font-size-25: 1.5625rem;
$font-size-26: 1.625rem;
$font-size-27: 1.6875rem;
$font-size-28: 1.75rem;
$font-size-30: 1.875rem;
$font-size-32: 2rem;
$font-size-36: 2.25rem;
$font-size-3-9: 13.9px;
/*Font Weight*/
$fontWeight-200: 200;
$fontWeight-300: 300;
$fontWeight-400: 400;
$fontWeight-500: 500;
$fontWeight-600: 600;
$fontWeight-700: 700;
/*PageWidth*/
$innerHeaderWidth: 1360px;
$bodyWrapperWidth: 1200px;

@font-face {
  font-family: LucidaGrande-Bold;
  src: url(./fonts/LucidaGrandeBold.ttf);
}
@font-face {
  font-family: Lucida Grande;
  src: url(./fonts/LucidaGrande.ttf);
}
@font-face {
  font-family: "Work Sans";
  src: url("./fonts/WorkSans-Regular.svg#WorkSans-Regular") format("svg"), url("./fonts/WorkSans-Regular.ttf") format("truetype"),
    url("./fonts/WorkSans-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Work Sans";
  src: url("./fonts/WorkSans.eot");
  src: url("./fonts/WorkSans.eot?#iefix") format("embedded-opentype"), url("./fonts/WorkSans.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Work Sans Extrabold";
  src: url("./fonts/WorkSans-ExtraBold.svg#WorkSans-ExtraBold") format("svg"), url("./fonts/WorkSans-ExtraBold.ttf") format("truetype"),
    url("./fonts/WorkSans-ExtraBold.woff2") format("woff");
  font-weight: normal;
  font-style: normal;
}

html,
body {
  height: 100%;
  box-sizing: border-box;
  margin: 0;
}
body {
  font-family: "Work Sans", "sans-serif" !important;
  margin: 0px !important;
  background-image: none;
  left: 0px;
  margin-left: auto !important;
  margin-right: auto !important;
  font-weight: $fontWeight-400;
  color: $bodyDefaultColor !important;
  font-style: normal;
  letter-spacing: normal;
  margin-bottom: 60px;
  font-size: $font-size-16;
  font-feature-settings: "kern";
  text-rendering: optimizeLegibility;
}
body.modal-open {
  padding-right: 0px !important;
}

option {
  -moz-appearance: none;
  font-family: "Work Sans" !important;
}
/*Common classes used*/
.redColorText {
  color: $redColorText;
}
.whiteColor {
  color: $white-color;
}
ul {
  list-style: none;
}
/*Common Anchor color set*/
a {
  color: $anchor-color;
  outline: 0;
  cursor: pointer;
}
a:active {
  color: $anchor-color;
  outline: 0;
  cursor: pointer;
}
a:hover {
  color: $anchor-color !important;
  text-decoration: none !important;
  outline: 0;
  cursor: pointer;
}
a:visited {
  color: $anchor-color;
  outline: 0;
  cursor: pointer;
}
.mandatoryField {
  color: $mandatoryField;
  font-size: $font-size-14;
}
.btn,
.btn:hover,
.btn:active,
.btn:visited {
  outline: 0;
}
.hide {
  display: none;
}
.show {
  display: block;
}
a label {
  cursor: pointer;
}
select {
  background: transparent;
}
.logout-btn:hover {
  color: $anchor-color !important;
}
/*Common Buttons*/
.btn {
  height: calc(1em + 0.75rem + 10px);
}
.custom-fluid,
.modal {
  .agiaBlueButton {
    background-color: $active-button-color;
    border-color: $active-button-color;
    color: $white-color;
    height: calc(1em + 1rem + 10px);
    font-weight: $fontWeight-500;
  }
  .agiaBlueButton-outline {
    border-color: $active-button-color;
    color: $active-button-color;
    height: calc(1em + 1rem + 10px);
    font-weight: $fontWeight-500;
  }

  .agiaBlueButton:hover {
    background-color: $active-button-color-hover;
    color: $white-color;
    height: calc(1em + 1rem + 10px);
    font-weight: $fontWeight-500;
  }
  .agiaBlueButton-outline:hover,
  .agiaBlueButton-outline:focus {
    background-color: #3399cc14;
    border-color: $active-button-color-hover;
    color: $active-button-color-hover;
    height: calc(1em + 1rem + 10px);
    font-weight: $fontWeight-500;
  }

  .agiaBlueButton:focus,
// .custom-fluid .agiaBlueButton-outline:not(:disabled):not(.disabled):active:focus,
.agiaBlueButton:not(:disabled):not(.disabled):active:focus {
    background-color: $active-button-color !important;
    color: $white-color;
    box-shadow: none !important;
    background: none;
    font-weight: $fontWeight-500;
  }

  .btn.disabled,
  .btn:disabled {
    opacity: 0.5;
    cursor: default;
    background-color: $active-button-color;
    border-color: $active-button-color-hover;
    color: $white-color;
  }
  .btn-danger.disabled,
  .btn-danger:disabled {
    opacity: 0.5;
    cursor: default;
    background-color: #dc3545;
    border-color: #dc3545;
    color: $white-color;
  }
  .btn.agiaBlueButton-outline.disabled {
    opacity: 0.5;
    cursor: default;
    background: $white-color;
    border-color: $active-button-color-hover;
    color: $anchor-color;
  }

  .materialicons {
    color: #718392;
    font-family: "MaterialIcons-Regular", "Material Icons";
    font-weight: 400;
    font-style: normal;
    font-size: 24px;
    text-align: left;
    vertical-align: middle;
    margin-right: 5px;
    top: 1px;
    position: relative;
    text-transform: none;
  }
  .materialicons.attachMoney {
    width: 16px;
  }
  button:focus {
    outline: 0;
  }
  button.showMoreButton {
    width: 100%;
    background: #fff;
    border: 0 none;
    padding: 15px;
    border-width: 1px;
    border-style: solid;
    border-color: #ecf0f3;
    border-radius: 3px;
    box-shadow: 2px 2px 10px #00000014;
  }
  .grayButton {
    background-color: rgba(255, 255, 255, 1);
    border-width: 1px;
    border-style: solid;
    border-color: rgba(214, 223, 228, 1);
    border-radius: 4px;
    font-size: 16px;
    color: $bodyDefaultColor;
    font-weight: $fontWeight-400;
  }
}
span.devider {
  position: relative;
  margin: 0px 15px;
  border: 0.5px solid $borderDark;
  width: 1px;
  display: inline-block;
}
.mainHeadingT {
  font-size: $font-size-36;
  color: $bodyDefaultColor;
  font-weight: $fontWeight-300;
}
/*common button color change for Login*/

input[type="checkbox"] {
  width: 24px;
  height: 26px;
  background: rgba(255, 255, 255, 1);
  background-color: rgba(255, 255, 255, 1);
  box-sizing: border-box;
  border-width: 2px;
  border-style: solid;
  border-color: rgba(214, 223, 228, 1);
  border-radius: 3px;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-family: "LucidaGrande-Bold", "Lucida Grande Bold", "Lucida Grande";
  font-weight: 700;
  font-style: normal;
  font-size: 16px;
  color: #ffffff;
  float: left;
}
/*common button color change for Login*/
span.dateValue {
  margin-right: 10px;
  display: inline-block;
  width: 90px;
}

/*Global Input Style*/
input[type="text"],
input[sda],
select {
  border: 1px solid $borderDark !important;
  border-radius: 4px !important;
  height: calc(1.5em + 0.75rem + 7px) !important;
}
input[type="text"]:hover,
select:hover {
  border: 1px solid $closeIconColor !important;
}
input[type="password"].password,
input[type="text"].password {
  padding-right: 84px;
}
input[type="text"]:focus,
input[type="password"]:focus {
  background-color: $white-color !important;
  box-shadow: none;
  border: 1px solid #3399cc !important;
}
input[name="iAgree"] {
  position: relative;
  top: 15px;
  margin-right: 5px;
}
/*GLobal Input*/

.privacyPolicy input[name="iAgree"] {
  position: relative;
  top: 0px;
  margin-right: 5px;
}
// .grayColor {color: $common-grayColor;}
.custom-fluid.container-fluid {
  padding-left: 0px;
  padding-right: 0px;
  background-color: $base-color;
  float: left;
  .mainWrapper {
    width: 100%;
    .bodyWrapper {
      /*max-width:1330px;*/
      max-width: $bodyWrapperWidth;
      margin: 127px auto 0;
      background-color: $base-color;
      min-height: 100%;
    }
    .bodyWrapper.not-logged-in {
      max-width: $innerHeaderWidth;
      margin-top: 80px;
      height: 100%;
      min-height: 830px;
    }
    .bodyWrapper .usernameWrapper,
    .bodyWrapper .registerWrapper {
      margin-top: -45px;
      padding: 15px;
      float: left;
      width: 100%;
    }
    .bodyWrapper .registerWrapper .usernameWrapper {
      padding: 0px;
    }
  }
}

.loginWrapper,
.loginWrapperInner {
  content: "";
  background-image: url("./_assets/images/background.jpg");
  max-width: 1360px;
  width: 100%;
  height: 100%;
  min-height: 830px;
  background-size: cover;
  position: absolute;
  background-repeat: no-repeat;
}
.loginWrapper {
  .loginBodyRight {
    max-width: 426px;
    width: 100%;
    float: right;
    margin-top: 4.15%;
    margin-right: 7.8%;
    position: relative;
    z-index: 1;
    margin-bottom: 40px;
  }
  .loginColumn {
    max-width: 426px;
    width: 100%;
    float: right;
    background: $white-color;
    text-align: left;
    padding: 25px;
    margin-bottom: 40px;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(236, 240, 243, 1);
    border-radius: 3px;
    input {
      width: 100%;
      padding: 9px 15px !important;
      border-radius: 4px !important;
    }
    .username {
      margin-top: 15px;
      float: left;
      width: 100%;
      label {
        margin: 0px;
      }
    }
    .password {
      padding-top: 20px;
      float: left;
      width: 100%;
      label {
        margin: 0px;
      }
    }
    .checkRemember {
      width: 100%;
      float: left;
      margin-left: -4px;
      input#rememberCheck {
        width: 24px;
        height: 26px;
        margin-right: 10px;
        //   background:rgba(255, 255, 255, 1);
        //   background-color:rgba(255, 255, 255, 1);
        //   box-sizing:border-box;
        //   border-width:2px;
        //   border-style:solid;
        //   border-color:rgba(214, 223, 228, 1);
        //   border-radius:3px;
        //   -moz-box-shadow:none;
        //   -webkit-box-shadow:none;
        //   box-shadow:none;
        //   font-family:'LucidaGrande-Bold', 'Lucida Grande Bold', 'Lucida Grande';
        //   font-weight:700;
        //   font-style:normal;
        //   font-size:16px;
        //   color:#FFFFFF;
        float: left;
      }
    }
    .roundBottomButton button {
      width: 134px;
      height: 40px;
    }
    .forgotPwd a {
      font-size: $font-size-16;
      text-decoration: underline;
      margin-top: 20px;
      display: inline-block;
      font-weight: $fontWeight-400;
    }
    .forgotPwd a:hover {
      text-decoration: underline !important;
    }
  }
  .registerWrapperMain {
    max-width: 426px;
    width: 100%;
    float: right;
    background: $white-color;
    text-align: left;
    padding: 25px;
    margin-bottom: 40px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.0784313725490196);
    border-width: 1px;
    border-style: solid;
    border-color: $lightBorder;
    border-radius: 3px;
    .loggedInText {
      font-size: $font-size-20;
      font-weight: $fontWeight-600;
    }
    #globalWC_loginFormRegInfo {
      margin: 20px 0px;
    }
  }
  .commonHeadText {
    font-size: $font-size-20;
    font-weight: $fontWeight-600;
    color: $bodyDefaultColor;
  }
}
.loginWrapperInner {
  background: rgba(255, 255, 255, 0.4);
}
.commonHeadText {
  font-size: $font-size-26;
  font-weight: $fontWeight-600;
}
.commInerHeadText {
  font-size: $font-size-26;
  font-weight: $fontWeight-600;
}

.mainHeader {
  width: 100%;
  background-color: $white-color;
  position: fixed;
  z-index: 2;
  left: 0px;
  top: 0px;
  right: 0px;
  box-shadow: rgba(181, 203, 216, 0.2) 0px 4px 10px;
  .logo {
    float: left;
    margin-right: 15px;
    img {
      /*max-width: 232px;width:100%;*/
      max-height: 80px;
      padding: 5px 0;
      height: auto;
      cursor: pointer;
    }
  }

  .innerHeaderBody {
    max-width: $innerHeaderWidth;
    width: 100%;
    margin: 0 auto;
    padding: 0px 15px;
    box-sizing: border-box;
    .navigationHeader {
      display: flex;
      width: 100%;
      position: fixed;
      left: 0px;
      right: 0px;
      top: 80px;
      background: $white-color;
      box-shadow: rgba(181, 203, 216, 0.2) 0px 4px 10px;
      border-width: 1px 0px 0px;
      border-style: solid;
      border-color: rgb(242, 242, 242);
      .navbarText {
        width: 100%;
        max-width: $innerHeaderWidth;
        margin: 0 auto;
        padding: 0px 15px;
        .navbar {
          padding: 0px;
          float: left;
          width: 80%;
          ul {
            a {
              color: $label-color;
              font-weight: 400;
            }
            label.materialicons {
              font-size: $font-size-20;
              margin: 0px 7.1px;
            }
            label.materialicons:first-child {
              margin-left: 0px;
            }
            li.nav-item.active a {
              color: $anchor-color;
            }
            li.nav-item.notifiacations {
              //background: url("./_assets/images/borderL.svg") no-repeat;/*margin-left: 8px;*/	position: relative;	top: 6px;
              span label.materialicons {
                cursor: pointer;
                margin: 0px 7.1px;
              }
              span label.materialicons:hover {
                color: $anchor-color;
              }
            }
            .phoneIcon {
              color: $bodyDefaultColor;
            }
            li.nav-item.notifiacations a label.materialicons {
              position: relative;
              top: -4px;
              right: -5px;
            }
            li.nav-item.notifiacations label.navText {
              cursor: pointer;
              position: relative;
              display: inline-block;
              width: 17px;
              height: 17px;
              background-color: #e25c83;
              border-radius: 17px;
              position: relative;
              top: -15px;
              left: -18px;
              sup {
                top: 8px;
                left: 5px;
              }
            }
          }
        }
      }

      .navbar-expand-lg {
        max-height: 45px;
        width: 100%;
        .navbar-nav {
          height: 45px;
          line-height: 22px;
          flex-direction: row;
          .nav-link {
            padding-right: 10px;
            padding: 13px 20px;
            line-height: 14px;
            height: 45px;
            .navText {
              position: relative;
              top: 2px;
            }
          }
          .nav-link:first-child {
            padding-left: 0px;
          }
        }
      }
      .loginProfile {
        display: flex;
        float: right;
        height: 45px;
        line-height: 42px;
        span {
          a {
            color: $label-color;
          }
        }
        span.devider {
          height: 30px;
          top: 8px;
        }
      }
    }
  }
  .navbarText.mobileView {
    li.nav-item.notifiacations {
      span label.materialicons {
        cursor: pointer;
        margin: 0px 7.1px;
      }
      span:hover {
        color: $anchor-color;
      }
    }
    .phoneIcon {
      color: $bodyDefaultColor;
    }
    li.nav-item.notifiacations a label.materialicons {
      position: relative;
      top: -4px;
      right: -5px;
    }
    li.nav-item.notifiacations label.navText {
      cursor: pointer;
      position: relative;
      display: inline-block;
      width: 17px;
      height: 17px;
      background-color: #e25c83;
      border-radius: 17px;
      position: relative;
      top: -15px;
      left: -18px;
      sup {
        top: 8px;
        left: 5px;
      }
    }
  }
  /*Material Icon design*/
  // .navigationHeader {padding: 2px 15px;
  // 	.navbar{padding:0px;height:80px;}
  // 	a{color:$label-color;padding:15px 0px 11px 13px;margin-right: 6px;font-weight: 400;}
  // 	ul{	margin-left: 15px;/*margin-top:10px;*/
  // 		li.nav-item.active a{color:$anchor-color;}
  // 		li.nav-item.notifiacations {background: url("./_assets/images/borderL.svg") no-repeat;/*margin-left: 8px;*/	position: relative;	top: 6px;
  // 		span label.materialicons{cursor: pointer;}
  // 			span:hover{color:$anchor-color;}}
  // 		li.nav-item.notifiacations a label.materialicons{position: relative;top: -4px;right: -5px;}
  // 		li.nav-item.notifiacations label.navText{cursor: pointer; position: relative;display: inline-block;width: 17px;height: 17px;background-color: red;border-radius: 17px;position: relative;top: -20px;left: -20px;
  // 			sup{top: 8px;left: 5px;}}}}
  .headerRightColumn {
    display: flex;
    flex-direction: column;
    span a {
      color: $label-color;
    }
    span a:hover {
      color: $anchor-color;
    }
    .contactAbout {
      line-height: 30px;
      min-height: 26px;
      text-align: right;
      font-size: $font-size-14;
      span.devider {
        height: 20px;
        top: 7px;
      }
    }
    span.headerCallText {
      font-size: $font-size-14;
      display: flex;
      flex-direction: column;
      float: left;
      span.smallTimeText {
        font-size: $font-size-13;
      }
    }
    span.rightSecLogo {
      display: flex;
      flex-direction: row-reverse;
      img {
        max-height: 50px;
        // margin-top: 6px;
      }
    }
  }
}
.impersonateHeader + .mainHeader {
  top: 50px;
}
.custom-fluid.container-fluid .impersonateHeader + .mainWrapper .bodyWrapper {
  margin: 177px auto 0;
}
.impersonateHeader + .mainHeader .innerHeaderBody .navigationHeader {
  top: 130px;
}
.warningAlert {
  background-color: $warningAlertColor; /*width:100%; word-break: break-all;*/
}

.showHide {
  float: right;
  position: relative;
  top: -30px;
  right: 10px;
  cursor: pointer;
}
.logoImg {
  max-height: 60px;
}
// #rememberCheck {width: auto;margin-right: 6px;position: relative;top: 3px;}
// label[for="rememberCheck"]{font-weight:$fontWeight-400;font-size: $font-size-15;position: relative;top:1px;}
.bottomLinks {
  font-size: 12px;
  a {
    font-size: 14px;
    text-decoration: underline;
  }
}
.btn-links {
  color: $anchor-color;
  cursor: pointer;
  text-align: right;
  font-size: $font-size-15;
}
.fa-check-circle {
  font-size: 38px;
  margin-right: 6px;
  margin-top: 10px;
}
.custom-fluid .footerWrapper {
  /*margin-top: -40px;*/
  padding: 0px 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 50px;
  background-color: $footerColor;
  color: $label-color;
  padding: 10px 10px;
  margin-left: 0px;
  margin-right: 0px; /*position:absolute;bottom:0;*/
  .innterFooter {
    max-width: $innerHeaderWidth;
    width: 100%;
    margin: 0 auto;
    .footerColumn-left {
      text-align: left;
    }
    .footerColumn-right {
      text-align: right;
    }
  }
}
.footerColumn-left,
.footerColumn-right {
  a {
    padding: 5px;
    font-size: 14px;
    color: $label-color-1;
  }
}
.footerColumn-left span {
  font-size: $font-size-14;
}
.footerColumn-right span {
  padding: 0px 15px;
  color: $label-color-1;
}

.contentBlock {
  border: 1px solid $borderDark;
  display: flow-root;
  .headingText {
    background-color: $borderDark;
    padding: 5px;
    font-size: 18px;
  }
}
.myProfile {
  width: 100%;
  float: left;
  .value span {
    display: inline-block;
    width: 100%;
  }
  .btn-links {
    margin-top: 5px;
  }
}

// .flexBox {
// 	ul {padding: 12px;
// 		li {list-style: none;
// 			.label {float:left;display:block;text-align: right;text-transform: uppercase;max-width:140px;}
// 			.value{	float:left;display:block;font-weight: 500;}}}
// 	a {color: #111;text-decoration: underline;}}

.inactivePolicies {
  border-top: 1px solid $borderDark;
  border-bottom: 1px solid $borderDark;
}

.alertBanner {
  background-color: #cdf1e8;
}
.imgBanner {
  width: 15%;
  height: 54px;
}
.imgBanner2 {
  width: 85%;
  height: 54px;
}
.notificationtext {
  position: absolute;
  left: 135px;
  top: 75px;
}
.passwordPolicy {
  ul {
    li {
      color: #2f5ac8;
    }
  }
}
.questionAnswer {
  padding: 10px;
  width: 100%;
}
.bg-color-blue {
  background-color: #006da5;
  color: $white-color !important;
  padding: 0px !important;
}
.navbar-light {
  .navbar-nav {
    .nav-link {
      color: $white-color !important;
    }
  }
}
.logoutIcon {
  color: $white-color;
  padding-top: 7px;
  display: inline-block;
  cursor: pointer;
  img {
    width: 23px;
    height: 23px;
    float: left;
  }
}
nav[aria-label="breadcrumb"] {
  margin-bottom: 10px;
  a {
    text-decoration: underline;
  }
}
nav[aria-label="breadcrumb"] a,
nav[aria-label="breadcrumb"] p {
  font-size: 14px;
}
.myProfileContent {
  //width: 68%; margin: 0 auto;
  .customerInfo {
    margin: 20px 0px;
    font-weight: $fontWeight-500;
  }
}
.customerInfo label:nth-child(odd),
.securityBlock label:nth-child(1) {
  max-width: 150px;
  width: 100%;
  display: inline-block;
  line-height: 30px;
  text-align: left;
  font-size: 16px;
  font-weight: $fontWeight-400;
  color: $label-color;
}
.customerInfo label:nth-child(even) {
  color: $testing-font;
  font-weight: $fontWeight-400;
  font-size: $font-size-16;
}
.state,
.zipCode {
  display: inline-block;
  width: 31%;
  margin-right: 6px;
}
.state label,
.zipCode label {
  width: 100%;
}
.state select,
.securityQuestions select {
  width: 100%;
  padding: 10px 2px;
}
.securityQuestions {
  label {
    display: inline-block;
    width: 100%;
  }
}
/*Model CCSS change*/

body.modal-open {
  overflow: auto !important;
  .modal {
    padding-right: 0px !important;
    overflow-y: hidden !important;
    label {
      color: $label-color;
    }
    .modal-content {
      max-width: 500px;
      margin: 0 auto;
    }
    .modal-header,
    .modal-footer {
      width: 100%;
    }
    .modal-footer {
      //justify-content: flex-start;
      padding-left: 22px;
      border-top: 1px solid $borderDark;
    }
    .modal-header {
      display: table;
      padding: 15px !important;
      width: 100%;
      h4 {
        font-size: 20px; //text-align: center;
        width: 100%;
      }
      .modal-title {
        float: left;
        width: 95%;
        font-size: $font-size-20;
      }
      button.close {
        float: right;
        position: absolute;
        padding: 0.7rem 0px;
        text-align: right;
        color: $closeIconColor;
        font-size: $font-size-30;
        outline: 0px;
        opacity: 1;
        font-family: "MaterialIcons-Regular", "Material Icons";
      }
      // button.close:hover{float:right;position: absolute;padding:1rem 0px;text-align: right;color:$bodyDefaultColor;font-size:$font-size-32;outline: 0px;}
    }
    .modal-body {
      width: 100%;
      margin: 0 auto;
      max-height: 450px;
      overflow: auto;
      padding: 5px 15px;
      .passwordPolicy {
        margin-bottom: 30px;
        margin-top: 20px;
        color: $label-color;
        p {
          margin-left: 20px;
          line-height: 12px;
        }
        p.heading {
          margin-left: 0px !important;
        }
      }
    }
  }
  .modal-loading-screen-wrapper {
    float: left;
    width: 100%;
    margin-top: 15px;
  }

  .modal ul {
    padding-left: 0px;
  }
  .modal ul.nav {
    padding: 10px 0px;
    span.materialicons {
      top: 0px;
      margin-right: 10px;
    }
  }
  .bodyRow .flexBox li {
    padding: 5px 0px;
    display: flex;
    line-height: 50px;
  }
  li span.label {
    width: 225px;
    float: left;
    margin-right: 15px;
  }
  span.value {
    flex: 1 1;
    select {
      width: 230px;
    }
  }
}
.inputicon {
  display: none;
  &:checked {
    & + .inputImg {
      background: url("./_assets/images/checkbox_selected.svg") no-repeat;
      width: 25px;
      height: 25px;
      background-position: left center;
      display: inline-block;
      width: 100%;
      padding-left: 30px;
    }
  }
}
.productTable {
  border: 1px solid $lightBorder !important;
  border-bottom: 0 none;
  border-top: 0 none;
  .inputImg {
    background: url("./_assets/images/checkbox.svg") no-repeat;
    width: 25px;
    height: 25px;
    background-position: left center;
    background-size: auto 100%;
    display: inline-block;
    width: 100%;
    padding-left: 30px;
    margin-bottom: 0px;
  }
  td {
    select {
      padding: 10px 5px;
    }
  }
  .row {
    border-bottom: 1px solid $lightBorder;
    div {
      &.col {
        padding-right: 10px;
        padding-left: 10px;
        padding-top: 12px;
        padding-bottom: 12px;
        &:last-child {
          border-right: 0 none !important;
          text-align: right;
          padding-right: 20px;
        }
      }
    }
  }
  .divTableHeader {
    .row {
      background: $lightBorder;
      font-weight: 500;
      font-size: 16px;
      div {
        &.col {
          padding-bottom: 12px;
        }
      }
    }
  }
  .seeDetails {
    width: 100%;
    border-top: 1px solid $lightBorder;
    .flexBox {
      width: 30%;
      float: right;
      line-height: 30px;
      ol {
        li {
          &:nth-of-type(odd) {
            width: 80%;
            font-size: 15px;
            padding-right: 10px;
          }
          &:nth-of-type(even) {
            width: 20%;
            font-weight: bold;
            font-size: 15px;
          }
        }
      }
    }
  }
}
.table-bordered td,
.table-bordered th {
  border: 1px solid $lightBorder !important;
  border-right: 0 none !important;
  border-left: 0 none !important;
  padding: 0.5rem;
}
.table-bordered {
  border: 1px solid $lightBorder !important;
  thead {
    th {
      background: $lightBorder;
    }
  }
}

.billingDetailsPanel {
  .flexBox {
    float: right;
  }
  .labelName {
    display: inline-flex;
    width: 80%;
    font-size: 12px;
    background: #f1f1f1;
    cursor: pointer;
    margin-bottom: 0px;
  }
  div[aria-expanded="true"] {
    .imageToggle {
      background: url("./_assets/images/downArrow.svg") no-repeat;
      height: 25px;
      background-position: right center;
      font-size: 12px;
      transform: rotate(180deg);
      display: flex;
      position: absolute;
      top: 37px;
      right: 44px;
      cursor: pointer;
    }
  }
}
.billingDetailsPanel div[aria-expanded="false"] .imageToggle,
.billingDetailsPanel .imageToggle {
  background: #f1f1f1 url("./_assets/images/downArrow.svg") no-repeat;
  height: 12px;
  background-position: right center;
  font-size: 12px;
  display: flex;
  position: absolute;
  top: 43px;
  right: 46px;
  width: 12px;
  cursor: pointer;
}
.totalAmount {
  width: 100%;
  float: left;
  .flexBox {
    float: right;
    width: 30%;
    ol {
      margin-bottom: 0px;
      li {
        &:nth-of-type(odd) {
          font-weight: 600;
          font-style: normal;
          font-size: 20px;
          color: #8e8e8e;
          width: 80%;
          font-family: "Lato-Bold";
        }
        &:nth-of-type(even) {
          font-weight: 600;
          font-style: normal;
          font-size: 20px;
          color: #212121;
          line-height: 30px;
          width: 20%;
        }
      }
    }
  }
}
.topBtnLink {
  float: right;
  text-align: right;
  width: 100%;
  position: relative;
  top: 30px;
}
.btmBtnLink {
  float: left;
  width: 100%;
  text-align: right;
}
.btmBtnLink button,
.topBtnLink button {
  margin-left: 10px;
}
.productTableStr2 {
  width: 100%;
  float: left;
  margin-top: 30px;
}
.tableHeadButton {
  width: 100%;
}
.headTextTable {
  top: 8px;
}
.cardType {
  label {
    width: 100%;
    display: block;
  }
  select {
    width: 100%;
    padding: 10px 5px;
    border-radius: 5px;
    border: 1px solid $borderDark;
  }
}
.crossIcon {
  margin: 0px 5px 0px 8px;
}
.ccInfo {
  width: 48%;
  display: inline-block;
}
.panel {
  width: 100%;
  float: left;
}
button {
  line-height: 1 !important;
}
.productTable td a.amountDue,
.productTable div a.amountDue,
a.amountDue {
  text-decoration: none;
  padding-bottom: 2px;
  line-height: 27px;
  border-bottom: 1px dashed #067bca;
  color: #007bff;
}
.price {
  text-align: right;
  margin-right: 36px;
}

.contactInfo {
  .leftColumn,
  .rightColumn {
    width: 100%;
    background-color: $white-color;
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border-color: $lightBorder;
    border-radius: 3px;
    -moz-box-shadow: 2px 2px 10px #00000014;
    -webkit-box-shadow: 2px 2px 10px #00000014;
    box-shadow: 2px 2px 10px #00000014;
    float: left;
    margin-right: 3%;
    .bodyRow {
      .flexBox {
        ol {
          float: left;
        }
        a {
          color: $anchor-color;
          text-decoration: none;
        }
        ol li:nth-of-type(odd),
        ol li:nth-of-type(even) {
          text-align: left;
          vertical-align: top;
          padding: 10px;
          font-weight: 400;
          line-height: 23px;
          width: 45%;
        }
        ol li:nth-of-type(odd) {
          color: $label-color;
          font-size: $font-size-15;
        }
        ol li:nth-of-type(even) {
          color: #333;
          font-size: 1rem;
        }
      }
    }
  }
  .leftColumn {
    margin-right: 25px;
  }
  .rightColumn {
    margin-right: 0px;
  }
  .headerColumn {
    padding: 10px 15px;
    float: left;
    width: 100%;
    border-bottom: 1px solid $lightBorder;
    //  h5{font-size: 18px;width:70%;float: left;margin-bottom: 0px;}
    a {
      width: 30%;
      display: block;
      float: right;
    }
  }
}
.form-control {
  padding: 9px 15px !important;
  height: calc(1.5em + 0.75rem + 7px) !important;
}
/*Inner Body for center template.*/
.innerBodyWrapper {
  min-height: 380px; /*max-width:1000px;margin:0 auto;*/
  box-sizing: border-box; /*padding: 20px 75px 20px 75px;*/
  padding: 15px;
  background-color: $base-color !important;
  float: left;
  width: 100%;
  margin-bottom: 20px;
  .mainHeadingT {
    font-size: $font-size-36;
    color: $bodyDefaultColor;
    font-weight: $fontWeight-300;
  }
  .innerNavHeader {
    .navbar {
      padding: 0px;
      a {
        color: $label-color;
        padding: 8px 18px;
        label {
          margin-bottom: 0px;
        }
      }
      ul {
        margin-top: 5px;
        li.nav-item.active a {
          color: $anchor-color;
          border-bottom: 2px solid $anchor-color;
        }
      }
    }
  }
  .innerNavbutton {
    /*padding-right: 25px !important;*/
    position: relative;
    top: 10px;
    a {
      border: 1px solid $anchor-color;
      padding: 11px;
      border-radius: 20px;
    }
    a:hover {
      background-color: #ecf9ff;
    }
  }
}

/*FLex Box Style*/
.flexWhiteBox {
  margin-top: 20px;
  display: flex;
  margin-left: 0rem;
  flex-flow: row wrap; /*min-height: 273px;*/
  width: 100%;
  .leftColumn:nth-child(2n) {
    margin-right: 0px !important;
  }
  .leftColumn,
  .rightColumn {
    max-width: 480px;
    width: 100%;
    background-color: $white-color;
    box-sizing: content-box;
    border-width: 1px;
    border-style: solid;
    border-color: $lightBorder;
    border-radius: 3px;
    -moz-box-shadow: 2px 2px 10px #00000014;
    -webkit-box-shadow: 2px 2px 10px #00000014;
    box-shadow: 2px 2px 10px #00000014;
    .bodyRow {
      .flexBox {
        width: 100%;
        float: left;
        ul {
          float: left;
          width: 100%;
          padding: 0px;
          margin-bottom: 0px;
        }
        a {
          color: $anchor-color;
          text-decoration: none;
        }
        ul li {
          padding: 10px 15px;
          display: flex;
        }
        ul li span.label,
        ul li span.value {
          text-align: left;
          vertical-align: top;
          font-weight: 400;
          line-height: 23px;
        }
        ul li span.label {
          font-size: $font-size-3-9;
          width: 175px;
          display: inline-block;
          margin-right: 7.5px;
          text-transform: uppercase;
        }
        ul li span.value {
          flex: 1;
          font-size: $font-size-16; /*max-width:320px;width:100%;*/
          margin-left: 7.5px; /*max-width:295px;width:100%;*/
        }
      }
    }
  }
  .rightColumn {
    margin-left: 7.5px;
  }
  .leftColumn {
    margin-right: 7.5px;
  }

  .headerColumn {
    margin-bottom: 15px;
    padding: 10px 15px;
    float: left;
    width: 100%;
    border-bottom: 1px solid $lightBorder;
    h3 {
      font-size: $font-size-16;
      float: left;
      margin-bottom: 5px;
      margin-top: 5px;
      a {
        text-decoration: underline;
      }
      a:hover {
        text-decoration: underline !important;
      }
    }
    .headerButton {
      text-align: right;
      line-height: 30px;
      a {
        text-align: right;
        margin-right: 20px;
        display: inline-block;
      }
    }
  }
}
.flexWhiteBox .headerColumn h3 a {
  width: 200px;
  white-space: nowrap;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #39c !important;
  text-decoration: underline;
}
#payNowCollapseText .flexBox ul {
  border-top: 1px solid $borderDark;
  border-bottom: 1px solid $borderDark;
}
.collapseText .flexBox ul {
  border-top: 1px solid $borderDark;
  border-bottom: 1px solid $borderDark;
}
/*FLex Box Style*/

.empty {
  background: transparent !important;
  box-shadow: none !important;
  border: 0 none !important;
}
.homePage {
  .blueBackground {
    border-width: 0px;
    position: absolute;
    left: 0px;
    top: 127px;
    width: 100%;
    height: 296px;
    -ms-overflow-x: hidden;
    overflow-x: hidden;
    -ms-overflow-y: hidden;
    overflow-y: hidden;
    background-image: url("./_assets/images/blueBackground.png");
    background-position: left top;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-size: cover;
    background-origin: border-box;
    border: none;
    border-radius: 0px;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .alertBanner {
    background-color: #fffada;
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border-color: #ffed8d;
    padding: 10px 12px; /*border-radius: 4px;*/
  }
  .alertBanner button.close {
    color: $closeIconColor;
  }
  .welcomeText {
    text-align: left;
    margin-top: 25px;
    font-size: $font-size-36;
    color: #fff;
    font-weight: $fontWeight-500;
    span.welcome {
      font-weight: 200 !important;
    }
  }
}
div.addPaymentM.leftColumn {
  background: transparent !important;
}
div.addPaymentM.leftColumn {
  --border-color: #1a3547;
  --border-weight: 1px;
  --dash-size: 6px;
  --gap-size: 6px;
  flex-direction: column;
  justify-content: center;
  display: flex;
  min-height: 273px;
  position: relative;
  text-align: center;
  cursor: pointer;
  opacity: 0.4;
  .addPaymentButton div.materialicons {
    font-size: $font-size-36;
    text-align: center;
    color: $bodyDefaultColor;
  }
  .addPaymentButton div.navText {
    font-size: $font-size-20;
    text-align-last: center;
    color: $bodyDefaultColor;
  }
}
div.addPaymentM.leftColumn:hover {
  opacity: 1;
  --border-color: #1a3547 !important;
  .addPaymentButton div.materialicons {
    color: $anchor-color !important;
  }
  .addPaymentButton div.navText {
    color: $anchor-color !important;
  }
}
div.addPaymentM.leftColumn a:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(90deg, var(--border-color) 100%, transparent 100%) top left no-repeat,
    linear-gradient(
        90deg,
        transparent calc(var(--gap-size) / 2),
        var(--border-color) calc(var(--gap-size) / 2),
        var(--border-color) calc(var(--gap-size) / 2 + var(--dash-size)),
        transparent calc(var(--gap-size) / 2 + var(--dash-size))
      )
      top center repeat-x,
    linear-gradient(90deg, var(--border-color) 100%, transparent 100%) top right no-repeat, linear-gradient(0deg, var(--border-color) 100%, transparent 100%) top left no-repeat,
    linear-gradient(
        0deg,
        transparent calc(var(--gap-size) / 2),
        var(--border-color) calc(var(--gap-size) / 2),
        var(--border-color) calc(var(--gap-size) / 2 + var(--dash-size)),
        transparent calc(var(--gap-size) / 2 + var(--dash-size))
      )
      center left repeat-y,
    linear-gradient(0deg, var(--border-color) 100%, transparent 100%) bottom left no-repeat,
    linear-gradient(90deg, var(--border-color) 100%, transparent 100%) bottom left no-repeat,
    linear-gradient(
        90deg,
        transparent calc(var(--gap-size) / 2),
        var(--border-color) calc(var(--gap-size) / 2),
        var(--border-color) calc(var(--gap-size) / 2 + var(--dash-size)),
        transparent calc(var(--gap-size) / 2 + var(--dash-size))
      )
      bottom center repeat-x,
    linear-gradient(90deg, var(--border-color) 100%, transparent 100%) bottom right no-repeat, linear-gradient(0deg, var(--border-color) 100%, transparent 100%) top right no-repeat,
    linear-gradient(
        0deg,
        transparent calc(var(--gap-size) / 2),
        var(--border-color) calc(var(--gap-size) / 2),
        var(--border-color) calc(var(--gap-size) / 2 + var(--dash-size)),
        transparent calc(var(--gap-size) / 2 + var(--dash-size))
      )
      center right repeat-y,
    linear-gradient(0deg, var(--border-color) 100%, transparent 100%) bottom right no-repeat;
  background-size: var(--dash-size) var(--border-weight), calc(var(--dash-size) + var(--gap-size)) var(--border-weight), var(--dash-size) var(--border-weight),
    var(--border-weight) var(--dash-size), var(--border-weight) calc(var(--dash-size) + var(--gap-size)), var(--border-weight) var(--dash-size);
}
.findPlanText {
  font-size: $font-size-15;
  font-weight: 400;
}
.btn-primary.disabled,
.btn-primary:disabled {
  cursor: default !important;
}
.rememberPay {
  width: 100%;
  float: left;
  div {
    float: left;
    margin-right: 20px;
    margin-bottom: 0px !important;
    label {
      position: relative;
      top: -2px;
      right: -2px;
    }
  }
}
/*Latest Changes*/

.homeHederText {
  color: $bodyDefaultColor;
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
}
.homePayDues {
  // width:100%;
  // float: left;
  .homeHederText {
    color: $white-color;
    font-size: 20px;
    font-weight: 500;
    font-style: normal;
    padding-bottom: 10px;
  }
  .rightCol {
    table.productTable td,
    table.productTable th {
      padding: 10px;
    }
    table.productTable th {
      border-bottom: 1px solid $lightBorder;
    }
    table.productTable th:nth-child(3) {
      white-space: nowrap;
    }
  }
  .flexWhiteBox {
    margin-top: 0px;
    border-radius: 0;
    margin-bottom: 20px;
    .leftColumn,
    .rightColumn {
      border-radius: 2px;
      .headerColumn h3 a {
        font-weight: $fontWeight-400;
      }
      a:hover {
        text-decoration: underline !important;
      }
      .flexBox ol {
        padding-bottom: 5px;
      }
    }
  }
}
.custom-fluid .productsTemplate table.productTable tr td:last-child {
  text-align: left;
}
.custom-fluid .productsTemplate table.productTable tr th td.amount {
  white-space: nowrap;
}
// .homePayDues.singleColLayout{
// 	.leftCol{width:35%;float:left;}
// 	.rightCol{width:65%;float:left;}
// }
.homePayDues.twoColLayout {
  .leftCol {
    max-width: 480px;
    width: 100%;
    float: left;
    margin-right: 15px;
  }
  .rightCol {
    /*max-width:640px;*/
    width: 100%;
    float: left;
    .homeHederText {
      color: $bodyDefaultColor;
      margin-top: 15px;
    }
  }
  .leftCol:nth-child(odd) {
    margin-right: 0px;
  }
  .leftCol .leftColumn {
    margin-right: 0px !important;
  }
  .flexWhiteBox .leftColumn .bodyRow .flexBox ul li span.label {
    width: 195px;
  }
}

.footerTextPara {
  float: left;
  margin-bottom: 0px;
  color: $label-color;
  font-size: $font-size-14;
}
.custom-fluid .homePayDues {
  margin: 15px 0px 35px 0px; /*float:left;width:100%;*/
}
// .custom-fluid .homePayDues .footerTextPara{float:right;margin-bottom:10px;color:$label-color;font-size: $font-size-14;}

.custom-fluid table.productTable {
  border-width: 0px;
  background-color: #ffffff;
  box-sizing: border-box;
  border: 1px solid $lightBorder !important;
  border-radius: 4px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  -moz-box-shadow: 2px 2px 10px #a5b9c526;
  -webkit-box-shadow: 2px 2px 10px #a5b9c526;
  box-shadow: rgba(165, 185, 197, 0.15) 2px 2px 10px;
  font-feature-settings: "kern";
  text-rendering: optimizeLegibility;
  th {
    //  padding: 18px 8px;
    padding: 15px 10px;
    font-size: $font-size-14;
    color: $tableFontColor;
    font-weight: normal;
    vertical-align: middle;
    text-align: left;
    border-top: 1px solid $lightBorder;
    border-bottom: 1px solid $lightBorder;
    text-transform: uppercase;
  }
  td {
    padding: 10px;
    font-size: $font-size-16;
    //color:$tableFontColor;
    font-weight: normal;
    vertical-align: middle;
    /*cursor: pointer;*/
    text-align: left;
    border-bottom: 1px solid $lightBorder;
    a:hover {
      text-decoration: underline !important;
    }
  }
  .amount {
    text-align: right;
  }

  tr:focus {
    background-color: rgba(51, 153, 204, 0.0980392156862745);
    outline: 0;
  }
  tr:focus td span.check {
    display: block;
  }
  tr:focus td span.uncheck {
    display: none;
  }
  span.anchorText {
    display: block;
    max-width: 232px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: $anchor-color;
    text-decoration: underline;
  }
  span.smallTableText {
    font-size: 14px;
    color: rgb(98, 113, 127);
    line-height: 23px;
  }
}
.custom-control-input {
  z-index: 2 !important;
  width: 22px;
  height: 22px;
  // margin-top: 8px;
}
.custom-control-add {
  margin-right: 50px;
}
input[type="radio"] {
  cursor: pointer;
}
input[type="radio"] ~ span.uncheck {
  display: inline-block;
  cursor: pointer;
}
input[type="radio"] ~ span.check {
  display: none;
  cursor: pointer;
}
input[type="radio"]:checked ~ span.uncheck {
  display: none;
  cursor: pointer;
}
input[type="radio"]:checked ~ span.check {
  display: inline-block;
  cursor: pointer;
}
.custom-radio .uncheck {
  color: $borderDark;
}
.custom-radio .check {
  color: $anchor-color;
}

.footerCol .bottomButton {
  float: left;
  width: 100%;
  margin-bottom: 25px;
  text-align: center;
  margin-top: 15px;
  .payNow {
    max-width: 298px;
    width: 100%;
    font-weight: 500;
    border-radius: 50px;
    height: 40px;
  }
}

.selected {
  background-color: rgba(226, 92, 131, 0.0980392156862745);
  border-color: rgba(236, 240, 243, 1);
}
.duePaymentButton {
  border-width: 0px;
  width: auto;
  padding: 6px 15px;
  text-align: center;
  background-color: rgba(226, 92, 131, 0.2);
  border: none;
  border-radius: 50px;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  color: $duePayment;
  cursor: text;
  display: inline-block;
  white-space: nowrap;
}
.autoPayButton {
  border-width: 0px;
  width: auto;
  padding: 6px 15px;
  text-align: center;
  background-color: rgba(160, 232, 183, 0.2);
  border: none;
  border-radius: 50px;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  color: $autoPayButton;
  cursor: text;
  display: inline-block;
  white-space: nowrap;
}

.paymentStatusBadge {
  border-width: 0px;
  width: auto;
  padding: 6px 15px;
  text-align: center;
  border: none;
  border-radius: 50px;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  cursor: text;
  display: inline-block;
  white-space: nowrap;
}

.duenow {
  color: $duePayment;
  background-color: rgba(226, 92, 131, 0.2);
}

.pastdue {
  color: rgb(167, 148, 68);
  background-color: rgba(226, 201, 92, 0.2);
}

.autopay {
  color: $autoPayButton;
  background-color: rgba(160, 232, 183, 0.2);
}

.nocosttoyou {
  color: rgba(92, 123, 226, 0.8);
  background-color: rgba(92, 123, 226, 0.2);
}

.combinedbill {
  color: rgba(226, 92, 226, 0.8);
  background-color: rgba(226, 92, 226, 0.2);
}

.paymentpending {
  color: rgba(77, 72, 73, 0.8);
  background-color: rgba(77, 72, 73, 0.2);
}

.default {
  color: $default;
  background-color: rgba(247, 247, 247, 0.2);
}

/*Tooltlp CSS start here */
.tooltip-inner,
.bs-tooltip-auto[x-placement^="right"] .arrow::before,
.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^="top"] .arrow::before,
.bs-tooltip-top .arrow::before {
  background-color: transparent !important;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
}

// .tooltipHover{background:url("./_assets/images/tooltipImg.svg") no-repeat 0px 0px ;position: relative;top:55px;left:-15px;width:267px;height:214px;color:$bodyDefaultColor;
// 	.flexBox{ width: 240px;	height: 214px; margin-right: 0px; margin: 0 auto;
// 		ul{
// 			padding:15px;
// 			li{text-transform: uppercase;line-height: 30px;}
// 			li span.label{width:70%;float:left;display:inline-block;text-align:left;}
// 			li span.value{width:25%;display:inline-block;text-align:right;}
// 		//    li:nth-of-type(odd){ text-align: left;width: 65%;float: left;padding-left: 10px;}
// 		//    li:nth-of-type(even){ text-align: left;width: 35%;float: left;text-align: right;}
// 		}
// 		p { text-align: left;width: 235px;margin: 0px auto;	padding: 8px 15px;}
// 	}
// }

.infoIcon {
  position: relative;
  left: 10px;
  left: 6px;
  top: -1px;
  color: $label-color-1;
}
.bs-tooltip-top .tooltip-inner {
  max-width: 225px;
}
.bs-tooltip-top .tooltipHover {
  background: url("./_assets/images/topHovertooltip.svg") no-repeat 0px 0px;
  position: relative;
  top: 0px;
  width: 239px;
  height: 95px;
  color: $bodyDefaultColor;
  .flexBox {
    width: 239px;
    height: 95px;
    padding: 15px;
    font-size: 14px;
    text-align: left;
  }
}

/*Tooltip Ends Here*/
.amtDate {
  font-size: $font-size-18 !important;
}
.amtDue {
  font-size: $font-size-18 !important;
  font-weight: $fontWeight-500 !important;
}
// .custom-fluid .homePayDues table.productTable td{cursor: default !important;}
// .homePayDues span.anchorText {color:$anchor-color;cursor: text;}
.homePayDues span.smallTableText {
  cursor: text;
}
.payNowaddPaymentM {
  min-height: 271px;
  .text {
    text-align: center;
    margin-bottom: 10px;
    font-size: $font-size-16;
    font-weight: $fontWeight-400;
  }
  .leftColumn {
    flex-direction: column;
    justify-content: center;
    display: flex;
    margin-top: 35px;
  }
  .roundBottomButton {
    text-align: center;
    span.materialicons {
      color: $white-color;
    }
    .agiaBlueButton {
      margin-right: 0px;
      .navText {
        position: relative;
        top: 2px;
        font-size: 1rem;
        font-weight: 500;
      }
    }
  }
}
/*DropDown Start*/

a[aria-expanded="false"].payNow-collapse-text .downArrow,
a[aria-expanded="false"].collapse-text .downArrow {
  display: none;
}
a[aria-expanded="true"].payNow-collapse-text .downArrow,
a[aria-expanded="true"].collapse-text .downArrow {
  display: block;
  display: inline-block;
  width: 20px;
  float: left;
}
a[aria-expanded="true"].payNow-collapse-text .leftArrow,
a[aria-expanded="true"].collapse-text .leftArrow {
  display: none;
}

span.materialicons.leftArrow,
span.materialicons.downtArrow {
  display: inline-block;
  width: 20px;
  float: left;
}

div#globalWC_login-form-reg-info {
  margin: 23px 0px;
}
.usernameWrapper,
.registerWrapper {
  /*min-height: 80vh;*/
  padding: 15px;
}
/*Drop Down End*/
/*Extra CSS need to remove later*/
// .myProfile {height: 725px;}
/*Extra CSS need to remove later*/
/*M CSS start*/
.mainWrapper dl dt {
  display: none;
}
.loading-screen-wrapper {
  z-index: 100000;
  position: fixed;
  background-color: rgba(255, 255, 255, 0.6);
  width: 100vw;
  height: 100vh;
}
.loading-screen-icon {
  position: absolute;
  top: 300px;
  left: 50%;
  transform: translate(-50%, -50%);
}
.spinner {
  margin: 100px auto 0;
  width: 70px;
  text-align: center;
}
.spinner > div {
  width: 18px;
  height: 18px;
  background-color: #333;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}
.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}
@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
// .registerWrapperMain button {border: 2px solid #ffc107;background: white !important;color: black;box-shadow: none;}
@media only screen and (max-width: 1024px) {
  .grecaptcha-badge {
    //visibility: hidden !important;
    //box-shadow: grey 0px 0px 5px !important;
    bottom: 64px !important;
  }
}
.recaptcha-v3-iframe {
  box-shadow: grey 0px 0px 5px !important;
}

.successAlert {
  background-color: $successAlert;
  border: 1px solid $autoPayButton;
}

.notification-box.infoAlert {
  background-color: rgba(255, 250, 218, 1);
  // color:$blackColor;
  border: 1px solid rgba(255, 237, 141, 1);
  margin-top: 0px;
  .notification-close {
    border: none;
    font-size: 24px;
    float: right;
    line-height: 23px;
    color: $closeIconColor;
    cursor: default;
    margin-right: 0px;
  }
}
.single-line-ellipses {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.notification-dashboard {
  min-height: 54px;
}
.notification-box {
  // word-break: break-word;
  white-space: pre-line;
  margin-bottom: 15px;
  margin-top: 5px;
  padding: 10px !important;
  border-radius: 4px;
  span.message {
    display: flex;
    flex-flow: column;
  }
  a {
    display: contents;
  }
}
select:disabled {
  background-color: #e9ecef;
  opacity: 1;
}
a.nav-link.active,
a.header-nav-link.active {
  color: #3399cc !important;
}
a.payment-center-header.active {
  color: #3399cc !important;
  border-bottom: 2px solid #3399cc;
}

// CSS fixes 12/12/2019 - Start
.notification-dashboard {
  min-height: 56px;
}
.tooltip.show {
  opacity: 1 !important;
}
.warningAlert {
  border: 1px solid #e25c83;
}

// CSS fixes 12/12/2019 - End

.desktopView.homePayDues.singleColLayout .flexWhiteBox .leftColumn {
  margin-right: 0;
}

/*Pagination CSS start From Here*/
.custom-fluid {
  .pagination {
    float: right;
    button {
      background: transparent;
      border: 0 none;
    }
    button[disabled] {
      opacity: 0.4;
    }
    select,
    select:focus {
      border: 0 none !important;
      padding: 0px;
      outline: 0;
    }
    span {
      display: flex;
      justify-content: center;
      flex-direction: column;
    }
    span.materialicons {
      margin: 0px 15px;
      font-size: 30px;
    }
    span.middlePaginationContent {
      margin: 0px 50px;
    }
    span.rowPages {
      margin-right: 15px;
    }
  }
}

.desktopView.loginNotification {
  max-width: 1200px;
  width: 100%;
  margin: 0px auto;
  position: relative;
  top: 15px;
  .notification-box {
    position: absolute;
    z-index: 1;
    width: 1200px;
    line-height: 30px;
  }
}
.desktopView.loginNotification ~ .loginWrapper .loginBodyRight {
  margin-top: 123px;
}
.table {
  color: #1a3547 !important;
}
.telNum {
  font-weight: $fontWeight-700;
}
.disabled-link-btn,
.disabled-link-btn:hover,
.disabled-link-btn:visited {
  color: #cecece !important;
  cursor: default;
}
.custom-fluid .productsTemplate table.productTable tr td:last-child,
.homePayDues table.productTable tr td:last-child {
  // display:flex;
  // justify-content: space-between;
  span.dateValue {
    line-height: 40px;
  }
  .paymentStatusBadge {
    line-height: 28px;
  }
}

.homePayDues.singleColLayout .flexWhiteBox .headerColumn {
  display: flex;
  h3 {
    flex: 1;
    a {
      max-width: 160px;
    }
  }
}
.displayFlex {
  display: flex;
}
.addPaymentMethodPopup {
  .modal-content {
    max-width: 980px !important;
    width: 98%;
  }
  .mainPopupWraper {
    width: 100%;
    display: flex;
    .leftPanel,
    .rightPanel {
      width: 50%;
    }
    .leftPanel {
      margin-right: 7.5px;
    }

    .rightPanel {
      margin-left: 7.5px;
    }
  }
  h2.bInfoText {
    font-size: 16px;
    margin: 10px 0px 15px 0px;
    font-weight: 600;
  }
  .modal-header button.close {
    right: 30px;
  }
}

@media (min-width: 576px) {
  .addPaymentMethodPopup .modal-dialog {
    max-width: 100%;
  }
}

.tooltipHover {
  /*background: url(../../static/media/tooltipImg.84d2214b.svg) no-repeat 0 0;*/
  // background-color: #fff;
  position: relative;
  top: 0px;
  left: 0px;
  /*width: 267px;*/
  width: 205px;
  height: 207px;
  color: #1a3547;
  font-family: Work Sans, "sans-serif" !important;
}
.tooltip-inner .tooltipHover {
  top: 47px;
  left: -12px;
  background: #fff;
}
.bs-tooltip-right .tooltipHover {
  border: 1px solid #d7dfe4;
  border-radius: 3px;
}
.bs-tooltip-right .tooltipHover:after,
.bs-tooltip-right .tooltipHover:before {
  right: 100%;
  top: 55px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.bs-tooltip-right .tooltipHover:after {
  border-color: rgba(255, 255, 255, 0);
  border-right-color: #ffffff;
  border-width: 10px;
  margin-top: -10px;
}
.bs-tooltip-right .tooltipHover:before {
  border-color: rgba(215, 223, 228, 0);
  border-right-color: #d7dfe4;
  border-width: 13px;
  margin-top: -13px;
}

.bs-tooltip-right .tooltipHover .flexBox {
  // width: auto;
  // height: auto;
  margin: 0 auto;
}

.tooltipHover .flexBox ul {
  padding: 15px 10px 15px 15px;
}

.tooltipHover .flexBox ul li {
  text-transform: uppercase;
  line-height: 30px;
  display: flex;
}

.tooltipHover .flexBox ul li span.label {
  width: 140px;
  /*float: left;
    display: inline-block;*/
  // flex: 1;
  text-align: left;
  white-space: nowrap;
}

.tooltipHover .flexBox ul li span.value {
  /* width: 25%;
    display: inline-block;*/
  text-align: right;
  white-space: nowrap;
  // padding-left: 5px;
  flex: 1;
}
.bs-tooltip-top .tooltipHover {
  background: url(./_assets/images/topHovertooltip.svg) no-repeat 0 0;
  position: relative;
  top: 0;
  width: 239px;
  height: 95px;
  color: #1a3547;
  background-color: none;
}

// Auto Pay Web Content
.auto-pay-wrapper {
  margin-bottom: 30px;
  float: left;
  img {
    max-width: 210px;
    width: 32%;
    min-width: 150px;
    float: left;
  }
  .set-up-auto-pay {
    display: block;
    font-size: 26px;
    color: #39c;
    position: relative;
    top: 15px;
    line-height: 32px;
    font-family: Work Sans Extrabold, "sans-serif";
    width: 600px;
  }
  .auto-pay-description {
    margin-top: 23px;
    font-weight: bold;
    font-size: 16px;
  }
}

@media (max-width: 767px) {
  .auto-pay-wrapper .set-up-auto-pay {
    font-size: 22px;
  }
  .auto-pay-wrapper .set-up-auto-pay {
    top: 0px;
    line-height: 28px;
    width: auto;
  }
  .auto-pay-wrapper .auto-pay-description {
    margin-top: 5px;
  }
}

.custom-fluid .materialicons.infoIcon,
.modal .materialicons.infoIcon {
  cursor: pointer;
}

.impersonateHeader {
  height: 50px;
  background-color: rgba(236, 240, 243, 1);
  padding: 5px 0 0 15px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
}

.document-error {
  color: red;
}

.custom-fluid.container-fluid .mainWrapper .impersonateHeader + .mainHeader + .bodyWrapper {
  margin-top: 177px;
}

@media screen and (max-width: 767px) {
  .custom-fluid.container-fluid .mainWrapper .impersonateHeader + .mainHeader + .bodyWrapper {
    margin: 0px 0px;
  }

  .cunsumerSearchBody .form-control {
    margin-top: 10px;
  }

  .cunsumerSearchBody button.form-control {
    padding: 0 !important;
  }
}

.table.productTable.productTableMarginBottom {
  margin-bottom: 55px;
}

.homePayDues .rightCol table.productTable th {
  padding-top: 15px;
  padding-bottom: 15px;
}

.custom-fluid table.productTable.searchCustomer td:last-child {
  width: 160px;
}

.tooltipHover .flexBox ul li p {
  text-transform: none;
  line-height: 20px;
  margin-top: 10px;
}

.tooltip-breakdown-disclaimer {
  text-transform: none;
  line-height: 20px;
  margin-top: 10px;
  font-size: 0.66rem;
  text-indent: -6px;
}

.tooltipHover.payment-amount-breakdown {
  height: auto;
  width: 270px;
}

.tooltipHover.payment-amount-breakdown .flexBox ul {
  margin: 0;
}

span.devider.devider-header-logos {
  display: inline;
}

.tooltip-break-special-chars {
  //    border: 1px solid blue;
  width: 27px;
}

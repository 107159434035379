@media screen and (max-width: 1024px) {
  .innerBodyWrapper{padding:15px;margin-bottom: 56px;}
  .homePayDues.twoColLayout .leftCol{.leftColumn{margin-right:0px;}}
  .flexWhiteBox .headerColumn h3 {font-size:$font-size-15;line-height: 25px;}
   .duePaymentButton,.autoPayButton {font-size:$font-size-12;padding:6px 12px;}
   .flexWhiteBox .leftColumn .bodyRow .flexBox ol li:nth-of-type(odd), 
   .flexWhiteBox .rightColumn .bodyRow .flexBox ol li:nth-of-type(odd){font-size: $font-size-15;}
}

@media (min-width: 768px) {
  .contentBoxSize { display: flex; flex-direction: row;}
  .contentBoxColumn {flex: 1;}
  .mobileView{display: none;}
  .desktopView{display:block;}
}

@media screen and (max-width: 480px){
  .innerBodyWrapper .innerNavHeader .navbar a {padding: 4px;font-size:$font-size-14;}
  .flexWhiteBox .headerColumn .headerButton a {margin-right: 15px;font-size:$font-size-14;}
}
@media screen and (max-width: 330px){
.flexWhiteBox .headerColumn .headerButton a {margin-right: 5px;}
.flexWhiteBox .headerColumn h3 a{width: 120px;}
}
/*****************************************************************NEW CSS******************************/
@media screen and (width: 1024px){  
.tooltipHover{
    background-size:contain;
    // top:35px;
    // left:-12px;
    height: 180px;
    .flexBox{
      ul{padding:15px;
          li{line-height:25px;
              span.value{display: inline-block;text-align: right;}
        }
      }  
    }  
  }
  .tooltip-inner .tooltipHover {
      top: 35px;
      left: -12px;
      background: #fff;
  }
  .custom-fluid table.productTable span.anchorText{max-width: 190px;}
    .paymentMWrapper .flexWhiteBox .leftColumn .bodyRow .flexBox ul li span.label, 
  .paymentMWrapper .flexWhiteBox .rightColumn .bodyRow .flexBox ul li span.label{
      width:175px;
  }
  .impersonateHeader + .mainHeader .innerHeaderBody .navigationHeader {
    top: 134px;
  }
}
@media screen and (max-width: 992px) {
  .homePage .welcomeText{font-size: 32px;font-weight: 500;}
  .homePayDues .homeHederText{font-size:$font-size-16;}
  .duePaymentButton,.autoPayButton {font-size:$font-size-14;}
  .innerBodyWrapper .flexWhiteBox .leftColumn .bodyRow .flexBox ol li:nth-of-type(odd), .innerBodyWrapper .flexWhiteBox .rightColumn .bodyRow .flexBox ol li:nth-of-type(odd){
font-size: $font-size-13; }
  .flexWhiteBox .leftColumn .bodyRow .flexBox ol li:nth-of-type(odd), .flexWhiteBox .leftColumn .bodyRow .flexBox ol li:nth-of-type(even), .flexWhiteBox .rightColumn .bodyRow .flexBox ol li:nth-of-type(odd), .flexWhiteBox .rightColumn .bodyRow .flexBox ol li:nth-of-type(even){
padding: 6px 5px;font-size: $font-size-14;}
  .homePayDues.singleColLayout .rightCol table.productTable td, 
  .homePayDues.singleColLayout .rightCol table.productTable th{padding: 10px;}
  .materialicons{font-size:$font-size-16;}
  .custom-fluid table.productTable span.smallTableText{font-size:$font-size-11;}
  // .tooltipHover .flexBox{width: 190px;}
  .tooltipHover .flexBox ol li:nth-of-type(odd),.tooltipHover .flexBox ol li:nth-of-type(even){font-size: 10px;}
  .innerBodyWrapper .innerNavHeader .navbar ul.navbar-nav{ width: 100%; display:inline-block; float: left;
    li{ display: inline-block;max-width: 33%;width: 100%;text-align: center;white-space: nowrap;}
    }
  }

@media screen and (width: 768px) {
  .flexWhiteBox .leftColumn .bodyRow .flexBox ul li span.label, 
  .flexWhiteBox .rightColumn .bodyRow .flexBox ul li span.label{
        margin-right: 5px;
  }
  .flexWhiteBox .leftColumn .bodyRow .flexBox ul li span.value, 
  .flexWhiteBox .rightColumn .bodyRow .flexBox ul li span.value{
        margin-left: 5px;
        // max-width:177px;
  }
  .flexWhiteBox .rightColumn{margin-top: 15px;margin-left:0px;}
  .loginWrapper .loginBodyRight { margin-top: 40px;
    margin-right: 26px;
  }
  .tooltipHover{
    background-size: contain;
    // top: 20px;
    // left: -220px;
    transform: scaleX(-1);
    width: 180px;
    height: 150px;
    .flexBox{transform: scaleX(-1);
      ul{padding:7px 10px;font-size:$font-size-12;margin-left:0px;
          li{line-height:22px;
              span.value{text-align: right;display: inline-block;}
            }
      }  
    }  
  }
  .tooltip-inner .tooltipHover {
    top: 20px;
    left: -220px;
    background: #fff;
}
  .tooltipHover.productsMainDesktop{left: -150px;}
  // .mainHeader .logo img{max-height:60px;}
  .paymentMWrapper .flexWhiteBox .leftColumn .bodyRow .flexBox ul li span.label, 
  .paymentMWrapper .flexWhiteBox .rightColumn .bodyRow .flexBox ul li span.label{
      width:175px;
  }
  .mainHeader{height:60px;}
  .mainHeader .logo{margin-right: 10px;}
  .mainHeader .logo img{max-height: 60px;}
  .mainHeader .headerRightColumn span.rightSecLogo img{ max-height: 20px;}
  .mainHeader .headerRightColumn span.headerCallText{font-size: $font-size-13; margin-top: -10px;}
  .mainHeader .headerRightColumn .contactAbout span.devider {height: 15px;top: 4px;}
  .mainHeader .innerHeaderBody .navigationHeader{top: 60px;}
  .mainHeader .innerHeaderBody .navigationHeader .navbar-expand-lg .navbar-nav{flex-flow: initial;}
  .mainHeader .headerRightColumn .contactAbout{font-size: $font-size-13;}
  .mainHeader .headerRightColumn .contactAbout span.devider{height: 13px;top: 3px;}
  .custom-fluid.container-fluid .mainWrapper .bodyWrapper{margin-top: 160px;}
  .homePage .blueBackground{top:157px;}
  // .bodyWrapper.not-logged-in{margin-top: 60px;padding:15px;}
  .custom-fluid.container-fluid .mainWrapper .bodyWrapper.not-logged-in{margin-top: 60px;height: auto;}
  .myProfile .flexWhiteBox{flex-flow: row wrap;}
  .payNowaddPaymentM .leftColumn{margin-top: 0px;margin-right:0px;}
  .payNowTooltipLocation .tooltipHover{ left:-255px;}
  .custom-fluid table.productTable th,
  .custom-fluid table.productTable td{
    padding:10px 5px;
  }
.custom-fluid table.productTable span.anchorText{max-width: 150px;}
.addPaymentMethodPopup .modal-content{max-width: 700px !important;.state, .zipCode{width:45%; select{width:100%;}}}
.impersonateHeader + .mainHeader .innerHeaderBody .navigationHeader {
  top: 110px;
}
}

@media screen and (max-width: 767px){
  .addPaymentMethodPopup .modal-content {
    max-width: 100% !important;
    width:100%;
}

  .impersonateHeader {
    height: 40px;
    padding-top: 0px;
    font-size: 14px;
    position: static;
  }

  .impersonateHeader + .mainHeader + .bodyWrapper .homePage .blueBackground {
      top: 85px;
  }
.addPaymentMethodPopup .mainPopupWraper{display:inline-block;}
.addPaymentMethodPopup .mainPopupWraper .leftPanel, .addPaymentMethodPopup .mainPopupWraper .rightPanel{width:100%;.state, .zipCode{width:45%; select{width:100%;}}}
.addPaymentMethodPopup .mainPopupWraper .rightPanel{    margin-left: 0px;}
.addPaymentMethodPopup .mainPopupWraper .leftPanel{    margin-right: 0px;}
.telNum{color:$anchor-color;font-weight: $fontWeight-400;}
  .custom-fluid.container-fluid .mainWrapper .bodyWrapper.not-logged-in{min-height:400px;}
  // .mainHeader{max-height: 35px;position: static;height:35px;}
  .mainHeader{max-height: 60px;position: static;height:60px;display: flex; flex-direction: column; justify-content: center;    border-color: rgba(236, 240, 243, 1);border-width: 1px;
    border-style: solid;border-left: 0px; border-top: 0px; border-right: 0px; border-radius: 0px;}
  .loginWrapper .commonHeadText{text-align: center;}
  .custom-fluid.container-fluid .mainWrapper .bodyWrapper.not-logged-in{margin-top: 0px;}
  .homePage .blueBackground{top: 100px;}
  .mainHeader .logo{margin-right: 10px;}
   .mainHeader .logo img{max-height:35px;padding: 0;    max-width: 130px;   }
   .mainHeader .logo img.secondary-logo-mobile{max-height: 20px;}
   .mainHeader .innerHeaderBody{padding: 0px 10px; margin-top: 5px;}
  .innerBodyWrapper{padding:10px 10px 50px 10px;}
  .custom-fluid.container-fluid .mainWrapper .bodyWrapper{margin: 0px 0px;}
  .mainHeadingT{font-size: $font-size-28;text-align: center;}
  .mainHeader .innerHeaderBody .navigationHeader{border:0 none;}
  .loginWrapper .loginColumn{padding: 25px;    margin-bottom: 15px;background: transparent;border:0 none;min-height: 375px;}
  .loginWrapper .loginBodyRight{margin-top: 10px;margin-right:0px;}
  .loginWrapper .registerWrapperMain{
      padding:20px 10px;
      min-height: auto;
      width: 94%;
      margin-right: 3%;
      .roundBottomButton{
        text-align: center
      }
  }
  .loginWrapper::after{background:none;width:auto;}
  body.modal-open .modal .modal-body,body.modal-open .modal .modal-header{padding:10px !important;}
  .modal .subHeading{margin-bottom:10px;margin-top:0px;}
  body.modal-open .modal .modal-footer.callNow{justify-content:space-between;padding: 10px 15px;border:none;}
  body.modal-open .modal .modal-body.callNowBody{display: flex;flex-direction: column;padding: 0px 15px;
    span.callColor {color: $anchor-color;}}
  .mainHeader .rightColumn,
  .desktopView {display:none;}
  .mobileView{display: block;}
.navbarText.mobileView ul{display:flex; margin: 0px;padding: 0px;float: right;}
.navbarText.mobileView ul li a,.navbarText.mobileView ul li span{padding:0px 5px;}
.homePage .welcomeText span.welcome,.homePage .welcomeText span.nameColor{width: 100%;text-align: center;display: block;}
.homePayDues .flexWhiteBox .leftColumn .headerColumn h3, 
.homePayDues .flexWhiteBox .rightColumn .headerColumn h3{font-size:$font-size-16;font-weight:600;}
.homePayDues .homeHederText { font-size: 20px;}
.duePaymentButton, .autoPayButton{font-size:$font-size-14;}
.flexWhiteBox .leftColumn .bodyRow .flexBox ul li, 
.flexWhiteBox .rightColumn .bodyRow .flexBox ul li{
  padding:10px;
}
.flexWhiteBox .rightColumn{margin-left:0px;}
  .flexWhiteBox .leftColumn{
        margin-bottom: 20px;
    }
  .flexWhiteBox .leftColumn .bodyRow .flexBox ul li span.label, 
  .flexWhiteBox .rightColumn .bodyRow .flexBox ul li span.label{
        margin-right: 5px;
  }
  .flexWhiteBox .leftColumn .bodyRow .flexBox ul li span.value, 
  .flexWhiteBox .rightColumn .bodyRow .flexBox ul li span.value{
        margin-left: 5px;
  }
  .homePayDues .flexWhiteBox .leftColumn .bodyRow .flexBox ul li span.label, 
  .homePayDues .flexWhiteBox .rightColumn .bodyRow .flexBox ul li span.label{
        margin-right: 5px;
        max-width: 195px;
        width: 100%;
  }

.singleCol{width:100%;float:left}
.singleCol.activeRowTable{margin-top:15px;.homeHederText{color:$blackColor} .flexWhiteBox{margin-bottom: 20px;}}
.footerWrapper .innterFooter .footerColumn-left,
.footerWrapper .innterFooter .footerColumn-right{text-align: center !important;}
.innerBodyWrapper .mainHeadingT{text-align: center;font-size:$font-size-28;}
.innerBodyWrapper .innerNavHeader .navbar ul.navbar-nav{ width: 100%; display:inline-block; float: left;
    li{ display: inline-block;width: 32%;text-align: center;white-space: nowrap;}
}
.mobileFooterNav { position: fixed;bottom: 0;width: 100%;height:60px;background-color:rgba(255, 255, 255, 1);background-image:none;border:none;border-radius:0px;-moz-box-shadow:0px -5px 10px rgba(181, 203, 216, 0.2);-webkit-box-shadow:0px -5px 10px rgba(181, 203, 216, 0.2);box-shadow:0px -5px 10px rgba(181, 203, 216, 0.2);
  .innerfootWapper{
 ul{
    display: flex;
    flex-wrap: wrap;
    flex-direction: initial;
    width: 100%;
    li label.materialicons{display: block;text-align: center;color:$label-color}
    li label.materialicons:hover{color:$anchor-color}
    li label.navText{display: block;text-align: center;line-height: 15px;font-size: $font-size-15;}
    li.nav-item {margin:0 auto;font-size: 1rem;}
    li a.nav-link{padding-top: 0px;color:$label-color}
  }
  .materialicons {font-size: 22px;margin-bottom:0px;}
    } 
    nav{padding-top:5px;margin-bottom:5px;}
  }
  .headerPanelMore,.bodyPanelMore{width:100%;float:left;margin-bottom:20px;}
  .bodyPanelMore{.callText{margin: 20px 0px;} .horizonLine{width: 106%;margin-left: -12px;margin-top: 30px;}
  .horizonLine.bottom{margin:5px 0px}
    .navbarText{ margin:15px 0px;
      .navbar{padding:0px;} 
      ul {display: inline-block;flex-wrap: nowrap; flex-direction: column; width: 100%;
       li label.materialicons{display: inline;font-size: 28px;margin-right: 15px;}
       li label.navText{display: inline;font-size: 1.13rem;position: relative;top: 5px;}
       li.nav-item{padding: 5px 0px;}
       }
      }
      .footerPanelMore{width:85%;right:0;float:left;text-align: center;background-color: $footerColor; position:absolute;bottom:0;padding:5px;a{margin-right:20px;font-size:$font-size-14}}
}
  .panel {
      background-color:rgba(255, 255, 255, 1);
      background-image:none;
      border:none;
      border-radius:0px;
      -moz-box-shadow:-5px 0px 10px rgba(0, 0, 0, 0.149019607843137);
      -webkit-box-shadow:-5px 0px 10px rgba(0, 0, 0, 0.149019607843137);
      box-shadow:-5px 0px 10px rgba(0, 0, 0, 0.149019607843137);
      padding: 10px 15px;
      .logo{float: left;width: 97%;}
      .close{width: 3%;margin-top: 6%;float: left;}
      }
        .myProfileContent{width:100%;}
        .customerInfo{text-align: center;}
        .myProfile .headingText{text-align: center;}
        .customerInfo .profileName label {
    max-width: 170px;
    text-align: left;
    vertical-align: middle;
    width: 100%;
}
.customerInfo .dob label {
    width: 100% !important;
    max-width: 170px ;
    text-align: left !important;
  }
.flexWhiteBox.payNowaddPaymentM{min-height: 180px; margin-top: 0px; .leftColumn{margin-top:0px;}}
.bottomButton.equalWidth button {width: 47%;float: left;display: inline-block;margin-top: 15px;padding: 0px 16px !important;}
.innerBodyWrapper .innerNavbutton a{padding: 0px;border-radius: 20px;width: 100%;display: inline-block;text-align: center;}
.paymentMWrapper .flexWhiteBox:nth-child(odd) .leftColumn{margin-right:0px;}
.paymentMWrapper .flexWhiteBox:nth-child(even) .leftColumn{margin-left:0px;}
.flexWhiteBox .leftColumn{margin-right:0px;}
.myProfile .flexWhiteBox{display:flex;flex-flow:row wrap; .leftColumn{margin-right:0px;margin-bottom:20px;} .rightColumn{margin-left:0px;}}
.flexWhiteBox .headerColumn{padding: 10px 10px; /*display: contents;*/}
.paymentMWrapper{ padding-top: 10px;
  .flexWhiteBox{
        min-height: auto;
        div.addPaymentM.leftColumn{
          min-height: 100px;
        }
        .materialicons{line-height: 30px;}
  }
}
body.modal-open .bodyRow .flexBox li{line-height: 30px;}
body.modal-open span.value select{width: 100%;}
.custom-fluid.container-fluid .mainWrapper .bodyWrapper .registerWrapper{
  padding:10px; margin-top: 0px; 
  .usernameWrapper{
      padding:0px !important;
    }
  }
  button.btn.register-btn { padding: 10px 15px;}
  .productDocs span.materialicons.leftArrow, 
  .productDocs span.materialicons.downtArrow{
    float: right;
    padding-right: 25px;
  }
  .custom-fluid.container-fluid .mainWrapper .bodyWrapper .registerWrapper, .custom-fluid.container-fluid .mainWrapper .bodyWrapper .usernameWrapper{
    margin-top:0px;
  }
  .loginWrapper .loginColumn .roundBottomButton {
    text-align: center;
    button{
        max-width: 290px;
        margin: 0 auto;
        width:100%;
    }
    
  }
  .registerWrapperMain button.btn.register-btn{
     max-width: 290px;
        margin: 0 auto;
        width:100%;
}

  .loginWrapper .forgotPwd div:nth-child(1){
	text-align: left;
}
.loginWrapper .forgotPwd div:nth-child(2){text-align: right;}
.flexWhiteBox .headerColumn h3 a:active {color: #39c !important;}
.notification-box.infoAlert {margin-top: 17px;}
.notification-dashboard{min-height: 78px; width: 100%;margin: 0 auto;}

}
 
@media screen and (max-width: 480px) {
  .mobileNavPanel .bg-color-blue { margin-top: 15%;}
  .customerInfo label{font-size: $font-size-13 !important;line-height: 25px;}
  .loginColumn.p-4,.lusernameColumn.p-4{padding:0px !important;}
  .flexWhiteBox .headerColumn h3{font-size: $font-size-15;}
  .btn-links{font-size: $font-size-14;}
  .alertBanner .text{font-size:13px;}
  .alertBanner .close{font-size: 18px;}
  .innerBodyWrapper .homePayDues .flexWhiteBox .leftColumn .bodyRow .flexBox ol li:nth-of-type(odd){
    font-size: $font-size-13;
  }
  .innerBodyWrapper .homePayDues .flexWhiteBox .leftColumn .bodyRow .flexBox ol li:nth-of-type(even){
    font-size: $font-size-15;
  }
  .innerBodyWrapper .innerNavHeader .navbar ul.navbar-nav li:nth-child(2n) {
    margin-right: 1%;
}
.customerInfo .profileName label,.customerInfo .dob label{max-width: 110px;}
}

@media screen and (max-width: 380px) {
.alertBanner .text { font-size:$font-size-11;}
.homePage .welcomeText {font-size: 25px;}
.homePayDues .homeHederText { font-size: 20px;}
.duePaymentButton, .autoPayButton{font-size:$font-size-12;padding:6px 12px;}
.innerBodyWrapper .innerNavHeader .navbar a{font-size: $font-size-12;}
.mainHeader .navigationHeader ul li.nav-item.notifiacations label.navText{top:-15px}
  .flexWhiteBox .leftColumn .bodyRow .flexBox ul li span.value, 
  .flexWhiteBox .rightColumn .bodyRow .flexBox ul li span.value{
      max-width: 182px;
      font-size: $font-size-14;
  }
.flexWhiteBox .headerColumn h3 a{max-width:165px;}
}
@media screen and (max-width: 360px) {
  .homePayDues .flexWhiteBox .leftColumn .headerColumn h3, 
  .homePayDues .flexWhiteBox .rightColumn .headerColumn h3{font-size: $font-size-13;}
.innerBodyWrapper .innerNavHeader .navbar ul.navbar-nav li:nth-child(2n) {
    margin-right: 12%;
}
.innerBodyWrapper .innerNavHeader .navbar ul.navbar-nav li{
      width: 28%;
}
.innerBodyWrapper .innerNavHeader .navbar ul.navbar-nav li:nth-child(2n) {
    margin-right: 10%;
}
.flexWhiteBox .leftColumn .bodyRow .flexBox ul li span.label, 
.flexWhiteBox .rightColumn .bodyRow .flexBox ul li span.label{
      max-width: 170px;
}
.flexWhiteBox .leftColumn .bodyRow .flexBox ul li span.value,
.flexWhiteBox .rightColumn .bodyRow .flexBox ul li span.value{
  font-size: $font-size-13;
}
.flexWhiteBox .leftColumn .bodyRow .flexBox ul li span.label,
.flexWhiteBox .rightColumn .bodyRow .flexBox ul li span.label{
  font-size: $font-size-12;
}
  .bodyPanelMore .footerPanelMore a {
      margin-right: 20px;
      font-size: 12px;
  }
  .mobileFooterNav .innerfootWapper ul li.nav-item{
        font-size: 14px;
  }
  .bodyPanelMore .navbarText ul li.nav-item {
    padding: 0px 0;
}
.myProfile{
  .flexWhiteBox .leftColumn .bodyRow .flexBox ul li span.label, 
  .flexWhiteBox .rightColumn .bodyRow .flexBox ul li span.label{
    max-width: 35%;
  }
}

}
@media screen and (max-width: 420px) {
  .modal-body .nav li + li.nav-item .custom-radio {
      padding-left: 0;
  }
}
@media screen and (width: 320px) {
.homePayDues .flexWhiteBox .leftColumn .bodyRow .flexBox ul li span.label, 
.homePayDues .flexWhiteBox .rightColumn .bodyRow .flexBox ul li span.label{
          max-width: 175px;
  }
}

@media screen and (max-width: 480px){
	.myProfile{
		.flexWhiteBox{
			.bodyRow{
				.resubscribe-link{
          display: block;
          margin-top: 7px;
				}
				ul li span.label{
					max-width: 40%;           
				}
			}
		}
	}
  }
 

  @media  screen and (max-width: 960px) and (min-width: 768px){
    .myProfile{
      .flexWhiteBox{
        .bodyRow{
          .resubscribe-link{
            display: block;
            margin-top: 7px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    .flexWhiteBox .leftColumn .bodyRow .bodyRow .flexBox ul li span.label {
      max-width: 158px;
      }
      .flexWhiteBox .leftColumn .bodyRow .bodyRow .flexBox ul li span.value {
      text-align: right;
      max-width: 78px;
      }
      .tooltip-break-special-chars {
      font-size: .813rem;
      line-height: 23px;
      }
}
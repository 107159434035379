.productsDetails{
    .productDetailTemplate{
        margin-top:30px;
            .homeHederText{font-size: 20px;font-weight: 500;font-style: normal;padding-bottom:10px;}
    }
    .inActiveTable{
        margin-top: 80px;
    .inavtiveHeader{display:inline-flex;
        .homeHederText{margin-right:15px;}
        a.collapse-text {line-height: 28px;}
        }
        // .productTable tr th:first-child,.productTable tr td:first-child{width:78%;}
        // .productTable tr th:last-child,.productTable tr td:last-child{width:1%;}
    }
    .flexWhiteBox .leftColumn .bodyRow .flexBox ul li span.label, 
.flexWhiteBox .rightColumn .bodyRow .flexBox ul li span.label{width:195px;}
}
@media screen and (max-width:767px){
    .productsDetails .inActiveTable {
        margin-top: 15px;
        }
    .productsDetails .productDetailTemplate .homeHederText{padding-bottom: 0px;margin-bottom: 0px;}    
}

.homePayDues.singleColLayout{
    display:flex;
    width: 100%;
    .leftCol{width: 335px;/*float:left;box-sizing: content-box;*/margin-right: 15px;}
    .rightCol{/*max-width:640px;width: 100%;float:left;*/    flex: 1;}
    .flexWhiteBox .leftColumn .bodyRow .flexBox ul li span.label{
        max-width:195px;
        width: 100%;
    }
    .flexWhiteBox .leftColumn{
        // margin-right: 15px;
    }
}

// @media screen and (width: 1024px) {

// }
@media screen and (max-width: 1023px) {
.homePayDues.singleColLayout {
    display:block;
    .rightCol{
            clear:both;
            .homeHederText{color:#1A3547;}
        }   
    }
}

// @media screen and (width: 768px) {
// .homePayDues.singleColLayout {
//     display:block;
//     .rightCol{
//             clear:both;
//             .homeHederText{color:#1A3547;}
//         }   
//     }
// }
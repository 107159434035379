.paymentHistory{
    margin-top:20px;
    float: left;
    width:100%;
    .checkBoxShow{
            margin-bottom: 20px;
                display: flex;
        .showText{
            margin-right:20px;
                display: flex;
    justify-content: center;
    flex-direction: column;
        }
        select{width: 250px;padding: 10px;}
    }
    .pastPayments{margin-top:30px;}
}
@media screen and (max-width:767px){
    .paymentHistory .checkBoxShow select{
        width:91%;
    }
.paymentHistory .productsDesc  .productDocs ul li.collapse-text .priceValue{visibility: hidden;}
 .paymentHistory .productsDesc .collapse-text[aria-expanded="false"] .value .priceValue{visibility: visible;}
  .paymentHistory .productsDesc .collapse-text[aria-expanded="true"] .value .priceValue{visibility: visible;}
  .paymentHistory .productsDesc .flexWhiteBox .leftColumn{max-width:100%;}
  .paymentHistory .productsDesc  .value .priceValue{width:60%;margin-right:0px !important;text-align:right;}
  .productDocs span.materialicons.leftArrow, 
  .productDocs span.materialicons.downtArrow{width:30%;text-align:right;padding-right:0px !important;}
}

@media screen and (max-width:370px){
    .paymentHistory .productsDesc .value .priceValue{    width: 75%;}
    .productDocs span.materialicons.leftArrow, .productDocs span.materialicons.downtArrow{
       width: 20%;
    }
}
@media screen and (max-width:370px){
    .paymentHistory .productsDesc .value .priceValue{width: 70%;}
}
.payNowTemplate{
    h2.homeHederText{width:100%;float:left;font-size: 20px;font-weight: 500;}
    .leftCol{width:480px;float:left;margin-right:7.5px;}
    .rightCol{width:480px;float: left;margin-left:7.5px; .headerColumn{padding:3px 10px;border: none; position: relative;top: 4px;}}
   .headerColumn{h3{font-weight: 500;} select{width: 71%;float: left;padding: 5px;}  .btn-links-edit{ width: 28%;float: left;vertical-align: middle;position: relative;top: 11px; text-align: right;padding-left:1%;
       }
    }
    .payNowWrapper{    display: flex;}
    .flexWhiteBox .leftColumn .bodyRow .flexBox ul li span.label, .flexWhiteBox .rightColumn .bodyRow .flexBox ul li span.label{
            width:195px;
    }
    .bottomButton {float: left;width: 100%;margin: 15px 0px; button{margin-right:10px;border-radius: 40px; padding: 0px 25px;}}
    .desktopView{float: left;width:100%;}
}
.priceValue{float:left;}


@media screen and (max-width: 992px) {
    .payNowTemplate .rightCol .headerColumn{padding:6px 10px;}
    .mobileView.payNowTemplate .flexWhiteBox{margin-top:6px;}
}
@media screen and (max-width: 768px) {
.payNowTemplate .payNowWrapper{display: inline;}
.payNowTemplate .rightCol{margin-left: 0px;}
}

@media screen and (max-width: 767px) {
.payNowTemplate .headerColumn select{
            width: 80%;
}
.payNowTemplate .headerColumn .btn-links-edit{
        width:20%;
        text-align: right;
    padding-left: 20px;
}
.payNowTemplate .rightCol{
            margin-left: 0px;
}
}
